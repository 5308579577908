import ExpenseNew from '../../components/ExpenseNew';
import ExpenseList from '../../components/ExpenseList';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal, Card } from 'react-bootstrap';
import { ExpenseReport, ResponseResult, RouteParam, Expenses } from '../../types';
import { useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ExpenseReportEdit(){    
    const { id } = useParams<RouteParam>();
    const [formData, setFormData] = useState<Partial<ExpenseReport>>({});    
    const [showRevision, setRevision] = useState(false);

    const getExpenseReport = () => {
        Swal.fire("Informe", "Cargando gastos...");
        Swal.showLoading();
        request('/api/expensereport/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                setFormData(result.data);                
            }else{
                Swal.fire("Informe", "Ocurrió un error al cargar los gastos", "error");
            }
        })       
    }
    useEffect(()=>{
        getExpenseReport()
    },[]);

    const confirmSendToRevision = () => {
        setRevision(true);
      } 

    const SendToRevision = () => {
        Swal.fire("Informe", "Enviando informe a revisión...");
        Swal.showLoading();
        request('/api/expensereport/sendtorevision/'+id,{
            method: 'PUT',
            body: JSON.stringify(formData.user)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                Swal.fire("Informe", "El informe se envio a revisión correctamente");
                window.location.href = "/myexpenses";
            }else{
                Swal.fire("Informe", "Ocurrió un error al enviar el informe a revisión", "error");
            }
        })

    }

    return (
        <div id="ExpenseReports-form">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    {
                        formData.reject_message && (
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className="text-danger">El informe fue rechazado porque:</Card.Title>
                                    <Col>
                                        <p>{formData.reject_message}</p>
                                    </Col>
                                </Card.Body>
                            </Card>
                            
                        )
                    }

                    {
                        formData.status == "Aprobado" && (
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className="text-success">El informe fue aprobado.</Card.Title>
                                </Card.Body>
                            </Card>
                            
                        )
                    }
                    <Card>
                        <Card.Body>
                            <Card.Title>Detalles del informe de gastos</Card.Title>
                            
                            <Form>
                                <Form.Group className="mb-3" controlId="viaticDelivered">
                                    <Form.Label>Viáticos entregados:</Form.Label>
                                    <Form.Control required type="number" name="viatic_delivered" placeholder="" defaultValue={formData.viatic_delivered} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="destiny">
                                    <Form.Label>Destino:</Form.Label>
                                    <Form.Control required type="text" name="destiny" placeholder="" defaultValue={formData.destiny} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodOf">
                                    <Form.Label>Periodo de:</Form.Label>
                                    <Form.Control required type="date" name="period_of" placeholder=""  defaultValue={formData.period_of?.split('T')[0]} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodTo">
                                    <Form.Label>Hasta:</Form.Label>
                                    <Form.Control required type="date" name="period_to" placeholder=""  defaultValue={formData.period_to?.split('T')[0]} disabled = {true}/>
                                </Form.Group>                                
                            </Form>
                        </Card.Body>
                    </Card>                    
                    {
                        (formData.status == "Borrador" || formData.status == "Rechazado") && (
                            <div className="mt-5">
                                <ExpenseNew expense={id}></ExpenseNew>
                            </div>
                        )
                    }
                    <div className="mt-5">
                        <ExpenseList idReport={id ? id : '1'}></ExpenseList>
                    </div>
                    {
                        (formData.status == "Borrador" || formData.status == "Rechazado") && (
                        <div>
                            <Button onClick = {confirmSendToRevision} className = "btn-primary ms-2 mt-4">Enviar a revisión</Button>
                        </div>
                        )
                    }
                    {
                        formData.status == "Revisión" && (
                        <div>
                            <p className = "ms-2 mt-4"> El informe se envio a revisión</p>
                        </div>
                        )
                    }
                     
                    <div>
                        <a href="/myexpenses" className="btn btn-secondary ms-2 mt-4">Regresar</a>    
                    </div>                   
                </Col>
            </Row>
            <Modal show={showRevision} onHide={()=>{setRevision(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Enviar informe a revisión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de enviar este informe a revisión?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRevision(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={SendToRevision}>
                        Sí, estoy seguro
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ExpenseReportEdit;