import React, {useState, useEffect} from 'react';
import {Container} from 'react-bootstrap';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProtectedRoute from "./services/ProtectedRoute";
import Dashboard from './pages/Dashboard';
import Login from './pages/auth/Login';
import ConfirmPassword from './pages/auth/ConfirmPassword';
import ChangePassword from './pages/auth/ChangePassword';
import UserList from './pages/users/UserList';
import UserNew from './pages/users/UserNew';
import UserEdit from './pages/users/UserEdit';
import './App.scss';
import ProjectList from './pages/projects/ProjectList';
import ProjectAdd from './pages/projects/ProjectAdd';
import ProjectEdit from './pages/projects/ProjectEdit';
import ExpenseReportList from './pages/expenseReport/ExpenseReportList';
import MyExpenses from './pages/expenseReport/MyExpenses';
import ExpenseReportAdd from './pages/expenseReport/ExpenseReportAdd';
import ExpenseReportEdit from './pages/expenseReport/ExpenseReportEdit';
import UserExpenseDetails from './pages/expenseReport/userExpenseDetails';
import MovementList from './pages/movements/MovementList';
import MovementNew from './pages/movements/MovementNew';
import MovementEdit from './pages/movements/MovementEdit';
import ReportMovementList from './pages/reports/ReportMovementList';
import ReportHeadingMonth from './pages/reports/ReportHeadingMonth';
import ReportAccountMonth from './pages/reports/ReportAccountMonth';
import ReportHeadingHistory from './pages/reports/ReportHeadingHistory';
import ReportHeadingBalance from './pages/reports/ReportHeadingBalance';
import ReportHeadingYearly from './pages/reports/ReportHeadingYearly';
import ApproveRejectExpenseReport from './pages/expenseReport/ExpenseReportApproveReject';
import BudgetList from './pages/budgets/BudgetList';
import BudgetNew from './pages/budgets/BudgetNew';
import BudgetEdit from './pages/budgets/BudgetEdit';
import BudgetDetail from './pages/budgets/BudgetDetail';
import ProjectHeadingList from './pages/budgets/ProjectHeadingList';
import AssignConcept from './pages/budgets/AssignConcept';
import AccountList from './pages/accounts/AccountList';
import AccountNew from './pages/accounts/AccountNew';
import AccountEdit from './pages/accounts/AccountEdit';

import ConfigContext, { initialConfig } from './context/config.context';
import { getConfig } from './services/Config.service';

function App() {

  const [config, setConfig] = useState({ loading: true, data: initialConfig });

  const { loading, data } = config;

  useEffect(() => {
    async function getConfigAsync(){
      const result:any = await getConfig();
      if(result.data){
        setConfig({ loading: false, data: result.data });
      }
    }
    getConfigAsync();
  }
  , []);

  return (
    <div className="App">
      <ConfigContext.Provider value={data}>
        <Header></Header>
        <Container fluid className="page-body">
          <Router>
            <Switch>            
              <Route exact path="/">
                <Login></Login>
              </Route>
              <Route path="/login">
                <Login></Login>
              </Route>
              <Route path="/confirmation/:token">
                <ConfirmPassword></ConfirmPassword>
              </Route>
              <ProtectedRoute path="/changepassword" component={ChangePassword}/>
              <ProtectedRoute path="/dashboard" component={Dashboard}/>
              <ProtectedRoute path="/projects/new" setRole={['Administrador']} component={ProjectAdd}/>
              <ProtectedRoute path="/projects/edit/:id" setRole={['Administrador']} component={ProjectEdit}/>
              <ProtectedRoute exact path="/projects" setRole={['Administrador']} component={ProjectList}/>
              <ProtectedRoute path="/expensereport/new" setRole={['Administrador']} component={ExpenseReportAdd}/>
              <ProtectedRoute path="/expensereport/edit/:id" setRole={['Administrador']} component={ExpenseReportEdit}/>
              <ProtectedRoute path="/expensereport/expensedetails/:id" component={UserExpenseDetails}/>      
              <ProtectedRoute path="/expensereport/expenseapprovereject/:id" setRole={['Administrador']} component={ApproveRejectExpenseReport}/>     
              <ProtectedRoute exact path="/expensereport" setRole={['Administrador']} component={ExpenseReportList}/>
              <ProtectedRoute exact path="/budget" setRole={['Administrador']} component={BudgetList}/>
              <ProtectedRoute exact path="/budget/new" setRole={['Administrador']} component={BudgetNew}/>
              <ProtectedRoute exact path="/budget/edit/:id" setRole={['Administrador']} component={BudgetEdit}/>
              <ProtectedRoute exact path="/budget/details/:id" setRole={['Administrador']} component={BudgetDetail}/>
              <ProtectedRoute exact path="/projectheadinglist" setRole={['Administrador']} component={ProjectHeadingList}/>
              <ProtectedRoute exact path="/budget/asignarconcepto/:id" setRole={['Administrador']} component={AssignConcept}/>
              <ProtectedRoute exact path="/myexpenses" component={MyExpenses}/>
              <ProtectedRoute exact path="/users" setRole={['Administrador']} component={UserList}/>
              <ProtectedRoute path="/users/new" setRole={['Administrador']} component={UserNew}/>
              <ProtectedRoute path="/users/edit/:id" setRole={['Administrador']} component={UserEdit}/>
              <ProtectedRoute exact path="/movements" setRole={['Administrador']} component={MovementList}/>
              <ProtectedRoute path="/movements/new" setRole={['Administrador']} component={MovementNew}/>
              <ProtectedRoute path="/movements/edit/:id" setRole={['Administrador']} component={MovementEdit}/>
              <ProtectedRoute path="/reports/movements" setRole={['Administrador']} component={ReportMovementList}/>
              <ProtectedRoute path="/reports/year" setRole={['Administrador']} component={ReportHeadingYearly}/>
              <ProtectedRoute path="/reports/headings/month" setRole={['Administrador']} component={ReportHeadingMonth}/>
              <ProtectedRoute path="/reports/accounts/month" setRole={['Administrador']} component={ReportAccountMonth}/>
              <ProtectedRoute path="/reports/headings/history" setRole={['Administrador']} component={ReportHeadingHistory}/>
              <ProtectedRoute path="/reports/headings/balance" setRole={['Administrador']} component={ReportHeadingBalance}/>
              <ProtectedRoute exact path="/accounts" setRole={['Administrador']} component={AccountList}/>
              <ProtectedRoute path="/accounts/new" setRole={['Administrador']} component={AccountNew}/>
              <ProtectedRoute path="/accounts/edit/:id" setRole={['Administrador']} component={AccountEdit}/>
            </Switch>
          </Router>
        </Container>
        <Footer></Footer>
      </ConfigContext.Provider>
    </div>
  );
}

export default App;
