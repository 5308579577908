import React, { useState, useEffect } from 'react';
import {Button, Form, Card, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import { ResponseResult, User} from '../../types';
import { request } from '../../services/Request';


function ExpenseReportAdd(){

    const [users, setUsers] = useState<User[]>([]);

    const [formData, setFormData]:any = useState({
        user:'',        
        viatic_delivered: '',
        destiny: '',
        period_of: '',
        period_to: ''
    });

    useEffect(()=>{
        getUsers();

        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    }, []);

    const getUsers = () => {
        request('/api/user',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setUsers(result.data);
            }else{
                Swal.fire("Usuario", "Ocurrió un error al recuperar los usuarios", "error");
            }
        })
    }
  
    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();      
        Swal.fire("Informe", "Guardando informe...");  
        Swal.showLoading(); 
        request('/api/expensereport',{
            method: 'POST',
            body: JSON.stringify(formData)                
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/expenseReport";    
            }else{
                Swal.fire("Nuevo informe", "Ocurrió un error al agregar el informe");
            }
        })      
    }

    const onChange = (e: any) => {
        let data = formData;
        if(e.target.name == 'viatic_delivered' && e.target.value < 0){
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            data[e.target.name] = 0;
        }else{
            data[e.target.name] = e.target.value;
        }
        setFormData(data);        
    }

    return (
        <div id="ExpenseReportAdd-container mb-1">

            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Nuevo informe</Card.Title>
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3" controlId="user">
                                    <Form.Label>Nombre del comisionado:</Form.Label>
                                    <Form.Select required name="user" onChange={onChange}>
                                                    <option></option>
                                                    {
                                                        users.map((user:User,key:number)=>(
                                                            <option value={user._id}>{user.name} {user.lastname}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                </Form.Group>                                
                                <Form.Group className="mb-3" controlId="viaticDelivered">
                                    <Form.Label>Viáticos entregados:</Form.Label>
                                    <Form.Control type="number" name="viatic_delivered" step="0.01" placeholder="" onChange={onChange} required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="destiny">
                                    <Form.Label>Destino:</Form.Label>
                                    <Form.Control type="text" name="destiny" placeholder="" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodOf">
                                    <Form.Label>Periodo de:</Form.Label>
                                    <Form.Control type="date" name="period_of" placeholder="" onChange={onChange} required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodTo">
                                    <Form.Label>Hasta:</Form.Label>
                                    <Form.Control type="date" name="period_to" placeholder="" onChange={onChange} required/>
                                </Form.Group>
                                <div className="d-flex">
                                    <Button variant="primary" type="submit" className="ms-2 mt-4">
                                        Agregar informe
                                    </Button>
                                    <a href="/expenseReport" className="btn btn-secondary ms-2 mt-4">Cancelar</a>
                                    <Button variant="danger" type="reset" className="ms-2 mt-4">
                                        Borrar todo
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default ExpenseReportAdd;