import { createContext } from 'react';

export const initialConfig = {
    logo: '',
    menu: {
        budgets: false,
        expenses: false,
        reports: false,
        users: false
    },
    report: {
        monthly: false,
        yearly: false
    }
}

const ConfigContext = createContext(initialConfig);

export default ConfigContext;