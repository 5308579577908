import React, {useEffect, useState } from 'react';
import BudgetMovementNew from '../../components/BudgetMovementNew';
import { Table, Card, Modal, Row, Col, Button } from "react-bootstrap";
import { ResponseResult, Heading, RouteParam, Budget, BudgetMovement } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function AssignConcept(){
    const { id } = useParams<RouteParam>();       
    const [heading, setHeading] = useState<Partial<Heading>>({});
    const [budgets, setBudget] = useState<Partial<BudgetMovement>>({
        items:[]
    });
    const [percent, setPercent] = useState(0);
    const [mountHeading, setMountHeading] = useState(0);
    const [showAddBudgetMovement, setAddBudgetMovement] = useState(false);
    const [showRemove, setRemove] = useState(false);
    const [selectedBudgetMovement, setSelectedBudgetMovement] = useState<Partial<BudgetMovement>>({});

    useEffect( () => {            
        getHeading();
        getBudgets();
        getMountHeading();
    }, []);    

    const getHeading = () => {
        request('/api/heading/'+id,{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{
            if(result.data){            
                setHeading(result.data);
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al cargar el rubro seleccionado","error");
            }
        })
    }
    const getBudgets = () => {
        Swal.fire("Cargando...", "Se estan cargando los conceptos");
        Swal.showLoading();
        request('/api/budget/budgetsassign/'+id,{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{
            if(result.data){ 
                setBudget({items: result.data});
                let porcentaje = 0;
                for(let i = 0; result.data.length > i; i++){                           
                    porcentaje += result.data[i].percent;
                }
                Swal.close();
                setPercent(porcentaje);
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al cargar los presupuestos");
            }
        })
    }
    const getMountHeading = () => {
        request('/api/budget/mountheading/'+id,{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                if(result.data.mountheading < 0){
                    Swal.fire("Error", "No hay dinero ligado al rubro","error");
                    setMountHeading(0);
                }else{
                    setMountHeading(result.data.mountheading);
                }
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al cargar el balance del rubro");
            }
        })
    }

    const showBudgetMovementNew = () => {
        setAddBudgetMovement(true);
        Swal.fire("Cargando...", "Se estan cargando los conceptos disponibles");
        Swal.showLoading();
        setTimeout(()=>{
            const element = document.getElementById("budgetMovementNew");
            element?.scrollIntoView({block: "start"}) as unknown as HTMLElement;
            Swal.close();
        },1500);
    }

    const updateBudgetMovement = () => {
        request('/api/budget/editbudgetmovement',{
            method: 'PUT',
            body: JSON.stringify(budgets)
        })
        .then((result:ResponseResult)=>{
            if(result.data){ 
                Swal.fire("Asignar Concepto", "El porcentaje fue actualizado exitosamente", "success");
                getBudgets();
            }else{
                Swal.fire("Asignar Concepto", "Ocurrió un error al aactualizar el porcentaje");
            }
        })
    }

    const changePercent = (e: any, key:number, idBudget:string) => {
        let total = percent;
        const data = budgets;
        if(!e.target.value || e.target.value < 0){
            let index = -1;
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            for(let i=0;i<data.items.length;i++){
                if( data.items[i].budget._id == idBudget ){
                    index = i;
                }
            }
            if(index!=-1){
                total -= data.items[index].percent;
                data.items[index].percent = 0;
                setPercent(total);
            }
        }else{
            const currentValue = parseFloat(e.target.value);
            let index = -1;
            for(let i=0;i<data.items.length;i++){
                if( data.items[i].budget._id == idBudget ){
                    index = i;
                    if(currentValue < data.items[i].percent){
                        const rest = data.items[i].percent - currentValue;
                        total -= rest;
                    }else{
                        const rest =currentValue - data.items[i].percent;
                        total += rest;
                    }
                }
            }
            if(total > 100){
                const currentInput = document.getElementById(key+"_id") as HTMLInputElement;
                let max = percent;
                for(let i=0;i<data.items.length;i++){
                    if( data.items[i].budget._id == idBudget ){
                        max -= data.items[i].percent;
                    }
                }
                currentInput.value = (100-max).toString();
                currentInput.select();
                Swal.fire("Asignar rubro", "No se puede asignar mas del 100%");
            }else{
                if(index!=-1){
                    data.items[index].percent = currentValue;
                }
                setBudget({...budgets, ...data});
                setPercent(total);
            }
        }
    }

    const mountAssign = (idBudget:string)=> {
        let mountAssign = 0;
        budgets.items.filter((item:BudgetMovement)=>{
            if(item.budget._id == idBudget){
                mountAssign = (mountHeading * item.percent)/100
            }
        })
        return mountAssign;
    }

    const confirmRemove = (budgetmovement:BudgetMovement) => {
        setRemove(true);
        setSelectedBudgetMovement(budgetmovement);
    }
    const removeBudgetMovement = () => {
        setRemove(false);
        Swal.fire("Concepto","Eliminando concepto...");
        Swal.showLoading();
        request('/api/budget/removebudgetmovement/'+selectedBudgetMovement._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Conceptos","Se elimino el concepto exitosamente","success");
                getBudgets();
            }else{
                Swal.fire("Conceptos","Ocurrió un error al eliminar el concepto","error");
            }
        })
    }
    return(
        <div className="AssignConcept-container">
            <Row className="pt-5">
                <Col xs={12} md={{span:10,offset:1}}>
                { showAddBudgetMovement && (
                        <div className="mb-3" id="budgetMovementNew">
                            <BudgetMovementNew id={id ? id : '1'}></BudgetMovementNew>
                        </div>
                    )
                }
                    <Card>
                        <Card.Body>
                            <Card.Title>{heading.name}</Card.Title>
                            <Card.Text className="text-left mb-2 me-5">Cantidad Actual: ${mountHeading} </Card.Text> 
                            <Card.Text className="text-left mb-2">Porcentaje Asignado: {(percent).toFixed(2)}%</Card.Text>
                            <Button className="btn-primary float-end mt-3 mb-3" onClick={showBudgetMovementNew} >
                                Asignar concepto
                            </Button>
                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Monto Mensual</th>                                        
                                        <th>Porcentaje</th>                                        
                                        <th>Cantidad Asignada</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        budgets.items.map((BudgetMovement:BudgetMovement, key:number) => (
                                        <tr key={BudgetMovement._id}>                                                                                          
                                            <td>{BudgetMovement.budget.concept}</td> 
                                            <td className="text-end">${BudgetMovement.budget.mount_month}</td>
                                            <td className="">
                                                <input type="number" name="percent" defaultValue={BudgetMovement.percent} className="form-control w-50 float-end text-end" step="0.01" id={key +"_id"}
                                                onBlur={(e:React.ChangeEvent<HTMLInputElement>) => changePercent(e, key, BudgetMovement.budget && BudgetMovement.budget._id ? BudgetMovement.budget._id : '')}
                                                ></input>
                                            </td>
                                            <td className="text-end">$
                                                {
                                                    mountAssign(BudgetMovement.budget && BudgetMovement.budget._id ? BudgetMovement.budget._id : '')
                                                }
                                            </td>
                                            <td>
                                                <Button onClick={() =>{confirmRemove(BudgetMovement)}} className="btn-danger ms-2 mt-1">
                                                    Eliminar
                                                </Button>
                                            </td>                                           
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <Button variant="primary" className="w-25" onClick={updateBudgetMovement} >
                                Actualizar
                            </Button>
                            <a href="/projectheadinglist" className="btn btn-secondary ms-2 mt-4 mb-4">Regresar</a>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar concepto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el concepto {selectedBudgetMovement?.budget?.concept}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeBudgetMovement}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default AssignConcept;