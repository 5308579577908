import {useEffect, useState } from 'react';
import {ExpenseReport, ResponseResult, Expenses } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import {Table, Modal, Button, Card, Dropdown} from "react-bootstrap";
import { format } from 'date-fns';
import ExpenseEdit from './ExpenseEdit';

function ExpenseList({idReport}:{idReport:string}){    

    const [expenseReports, setExpenseReports] = useState<Partial<ExpenseReport>>({});    
    const [selectedExpenseReport, setSelectedExpenseReport] = useState<Partial<ExpenseReport>>({});
    const [showRemove, setRemove] = useState(false);  
    const [editData, setEditData] = useState<Partial<Expenses>>({});
    const [status, setStatus] = useState('');    

    useEffect( () => {
        getExpenseReport();
        getExpenses();
      }, []);

    const getExpenseReport = () => {
        request('/api/expenseReport/'+idReport,{})
        .then((result:ResponseResult)=>{
            if(result.data){       
                setStatus(result.data.status);                
            }else{
                Swal.fire("Informe", "No se pudo obtener el status del informe", "error");
            }
        })       
    }

    const getExpenses = () => {
        request('/api/expensereport/myexpenses/'+idReport,{})
        .then((result:ResponseResult)=>{
           if(result.data){
            setExpenseReports(result.data);
           }else{
               Swal.fire("Gastos", "Ocurrio un error al cargar los gastos")
           }           
        })
      }
    const confirmRemove = (expense:Expenses) => {
        setSelectedExpenseReport(expense);
        setRemove(true);
      } 

    const removeExpense = () => {
        setRemove(false);
        Swal.fire("Gasto","Eliminando gasto...");
        Swal.showLoading();
        request('/api/expensereport/rmexpense/'+selectedExpenseReport._id,{
            method: 'PUT'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getExpenses();
            }else{
                Swal.fire("Gasto","Ocurrió un error al eliminar el gasto","error");
            }
        })        
      }      
    const showEdit = (expense : Expenses) => {
        setEditData(expense); 
        Swal.fire("Gastos", "Cargando gasto...");
        Swal.showLoading();  
        setTimeout(()=>{
            const element = document.getElementById("expenseEdit");
            element?.scrollIntoView({block: "start"}) as unknown as HTMLElement;
            Swal.close();
        },1500);     
      }

    return (
        <div className="new-expense">
            {editData.concept != null &&
                <div className="mb-3" id="expenseEdit">
                    <ExpenseEdit data={editData}/>
                </div>
            }
            <Card>
                <Card.Body>
                    <Card.Title>Lista de gastos</Card.Title>
                    <Table striped bordered responsive className="bg-light text-dark">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Comprobante</th>
                                <th>Concepto</th>
                                <th>Moneda</th>
                                <th>Monto</th>
                                <th>Tipo de cambio</th>
                                <th>Total</th>
                                <th>Facturas</th>

                            </tr>
                        </thead>
                        <tbody>
                                {
                                    expenseReports?.expenses?.map((expense:Expenses, key:number) => (

                                        <tr key={key}>                                            
                                            <td>{format(new Date(expense.dateExpense), 'dd/MM/yyyy')}</td>
                                            <td>{expense.voucher}</td>
                                            <td>{expense.concept}</td>
                                            <td>{expense.coin}</td>
                                            <td className="text-end">${expense.mount}</td>
                                            <td>{expense.type_of_change}</td>
                                            <td className="text-end">${(expense.mount / expense.type_of_change).toFixed(2)}</td>
                                            {expense.invoices?.map((files:any,key:number)=>(
                                                <td><a href={"/api/expenseReport/file?id_invoice="+files._id+'&id_report='+idReport}>Archivo {key + 1}</a></td>
                                            ))}
                                            
                                            {
                                                ["Borrador","Rechazado"].includes(status)&& (
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="secondary" id="dropdown">
                                                                Opciones
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() =>{showEdit(expense)}}>Editar</Dropdown.Item>
                                                                <Dropdown.Item onClick={() =>{confirmRemove(expense)}}>Eliminar</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                )
                                            }
                                            
                                        </tr>
                                    ))
                                }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar gasto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el gasto de {selectedExpenseReport?.concept}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeExpense}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ExpenseList;