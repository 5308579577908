import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Form } from 'react-bootstrap';
import { ReportAccountData, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import { addDays, startOfMonth, endOfMonth } from 'date-fns';
import Swal from 'sweetalert2';

function ReportHeadingMonth(){

    const [accounts, setAccounts] = useState<ReportAccountData[]>([]);
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [selectedProject, setProject] = useState('');
    const [toggleExchange, setToggle] = useState(false);


    useEffect(()=>{
        let startMonth = new Date( startOfMonth(new Date()) );
        let endMonth = new Date( endOfMonth(new Date()) );
        setStart(startMonth);
        setEnd(endMonth);
        getMovements(startMonth, endMonth);
    }, []);

    const getMovements = (startMonth:Date, endMonth:Date) => {
        Swal.fire("Movimientos","Buscando...");
        Swal.showLoading();
        request('/api/movement/account/month?start='+(startMonth.toISOString())+'&end='+(  endMonth.toISOString()),{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setAccounts(result.data);
                setToggle(true);
            }
            Swal.close();
        })
    } 

    const getTotal = () => {
        let total = 0;
        for(let i=0; i<accounts.length; i++){
            total += (accounts[i].previous_total) - (accounts[i].current_egress_total) + accounts[i].current_ingress_total 
        }
        return total.toFixed(2);
    }

    const onChange = (e:any) => {
        if(e.target.name == 'start'){
            setStart( new Date(e.target.value+"T06:00:00.000Z") );
        }else if(e.target.name == 'end'){
            setEnd( new Date(e.target.value+"T06:00:00.000Z") );
        }
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reporte de cuentas en mes actual
                            </Card.Title>
                            <Row className="align-items-end">
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="start">
                                        <Form.Label>Desde</Form.Label>
                                        <Form.Control type="date" placeholder="" name="start" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="end">
                                        <Form.Label>Hasta</Form.Label>
                                        <Form.Control type="date" placeholder="" name="end" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:2}}>
                                    <button className="btn btn-primary w-100" onClick={() => getMovements(start, end)}>
                                        Buscar
                                    </button>
                                </Col>
                            </Row>
                                <Table striped bordered hover className="mt-3">
                                    <thead>
                                        <tr>
                                            <th>Cuenta</th>
                                            <th>Saldo anterior</th>
                                            <th>Gastos mes actual</th>
                                            <th>Ingresos</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            accounts.map((account:ReportAccountData,key:number)=>(
                                                <tr key={key}>
                                                    <td className="text-end">{ (account.account) }</td>
                                                    <td className="text-end">$ { (account.previous_total).toFixed(2) }</td>
                                                    <td className="text-end">$ { (account.current_egress_total).toFixed(2) }</td>
                                                    <td className="text-end">$ { (account.current_ingress_total).toFixed(2) }</td>
                                                    <td className="text-end"> $ { ((account.previous_total) - (account.current_egress_total) + account.current_ingress_total).toFixed(2) }</td>
                                                </tr>
                                            ))
                                        }
                                    <tr>
                                        <td colSpan={4}>
                                            Total
                                        </td>
                                        <td className="text-end">
                                            $ { getTotal() }
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportHeadingMonth;