import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { ExpenseReport, ResponseResult, Expenses } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import {useDropzone} from 'react-dropzone';

interface Props {
    expense? : string,
}

function ExpenseNew(props : Props){

    
    const { expense } = props;    
    const [formData, setFormData]:any = useState({                    
        voucher: '',
        concept:'',
        coin: '',
        mount: 0,
        type_of_change:1,
        dateExpense:'',
        invoices:[]
    });
    const [selectedFiles, setFiles]:any = useState([]);
    const [typeOfchange, setTypeOfchange] = useState(false);
    const [valueOfChange, setValueOfChange] = useState(1);
    
    const onDrop = (acceptedFiles:any) => {
        let files = selectedFiles;
        files = files.concat(acceptedFiles);        
        setFiles(files);
    };

    useEffect(()=>{
        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    },[expense]);

    const removeFile = (index:number) => {
        setFiles( selectedFiles.filter((item:any, key:number) => key !== index) );
    }

    const {getRootProps, getInputProps } = useDropzone({multiple:true, onDrop: onDrop});

    const onChange = (e:any) => {
        let data = formData;
        if(e.target.value == "USD"){
            setTypeOfchange(true);
        }else if(e.target.value == "MXN"){
            setTypeOfchange(false);
            setValueOfChange(1);
        }
        if(data[e.target.name] == "type_of_change"){
            if(e.target.value > 1){
                setValueOfChange(e.target.value);
            }
        }
        if(e.target.name == 'mount' && e.target.value < 0){
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            data[e.target.name] = 0;
        }else{
            data[e.target.name] = e.target.value;
        }
        setFormData(data);        
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(typeOfchange){
            if(formData.type_of_change == 0 || formData.type_of_change<0){
                Swal.fire('Gasto', 'El valor del tipo de cambio no puede ser 0 ni menor a él mismo','error');
                return false;
            }
        }
        if(!selectedFiles || selectedFiles.length == 0){
            Swal.fire('Gasto', 'Se debe ingresar al menos una factura al gasto','error');
            return false;
        }else{            
            const data = new FormData();
            for(let field in formData){                                
                data.append(field, formData[field]);             
            }
            for(let file of selectedFiles){                
                data.append('invoices', file);
            }            
            Swal.fire("Nuevo gastos", "Guardando gasto...");
            Swal.showLoading();
            request('/api/expensereport/addexpense/'+expense,{
                method: 'PUT',
                file:true,
                body: data                
            })
            .then((result:ResponseResult)=>{
                if(result.data){
                    window.location.reload();
                    Swal.fire("Nuevo gasto", "Se agrego el gasto con exito");
                }else{
                    Swal.fire("Nuevo informe", "Ocurrió un error al agregar el informe");
                }
            })
        }        
    }
    return (
        <div className="new-expense">
            <Card>
                <Card.Body>
                    <Card.Title>Agregar gasto</Card.Title>
                        <Form onSubmit={onSubmit}>
                            <Form.Group className="mb-3" controlId="voucher">
                                <Form.Label>Comprobante</Form.Label>
                                <Form.Select name="voucher" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onChange(e)} required>
                                    <option></option>
                                    <option value="Factura">Factura</option>
                                    <option value="Vale">Vale</option>
                                    <option value="Vale Azul">Vale Azul</option>
                                    <option value="Ticket">Ticket</option>                                                    
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="concept">
                                <Form.Label>Concepto:</Form.Label>
                                <Form.Control type="text" name="concept" placeholder="" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="coin">
                                <Form.Label>Moneda:</Form.Label>
                                <Form.Select name="coin" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onChange(e)} required>
                                    <option></option>
                                    <option value="MXN">Pesos</option>
                                    <option value="USD">Dolares</option>                                                                                                       
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="dateexpense">
                                <Form.Label>Fecha del gasto:</Form.Label>
                                <Form.Control type="date" name="dateExpense" placeholder="" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="mount">
                                <Form.Label>Cantidad:</Form.Label>
                                <Form.Control type="number" step="0.01" name="mount" placeholder="" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                            </Form.Group>
                            {
                                typeOfchange && (
                                    <Form.Group className="mb-3" controlId="type_of_change">
                                        <Form.Label>Tipo de cambio:</Form.Label>
                                        <Form.Control type="number" step="0.01" name="type_of_change" defaultValue = {valueOfChange} placeholder="" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                                    </Form.Group>
                                )
                            }
                            <hr />
                            <div className='file-drop justify-center mt-10' {...getRootProps()}>
                                <input {...getInputProps({multiple:true})} />
                                <p className="text-center mb-4">Arrastra y suelta tus archivos o presiona aquí para elegirlos.</p> 
                            </div>
                            <p><b>Archivos seleccionados:</b></p>
                            <ul className="mb-5">
                                {
                                    selectedFiles.map((file:any,key:number)=>(
                                        <li key={key}>
                                            { file.name } <button type="button" className=" mt-1 mb-1 btn btn-danger" onClick={() => removeFile(key)}>Eliminar</button>
                                        </li>
                                    ))
                                }
                            </ul>
                            <Button variant="primary" type="submit" className="ms-2 mt-4">
                                Agregar gasto
                            </Button>
                        </Form>
                </Card.Body>
            </Card>
        </div>
    );

}

export default ExpenseNew;