import React, {useState,useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { request } from './Request';
import { ResponseResult } from '../types';

const ProtectedRoute = ({setRole,...props}:any) => {

	const [returnedRoute, setRoute] = useState<JSX.Element>();

	useEffect(()=>{
		if(!setRole){
			setRoute( localStorage.token ? <Route {...props} /> : <Redirect to="/"/>);
		}else{
			request('/api/auth/me',{})
			.then((result:ResponseResult)=>{
				if(result.data){
					if(setRole.includes(result.data.role)){
						setRoute(<Route {...props} />);
					}else{
						setRoute(<Redirect to="/"/>);
					}
				}else{
					setRoute(<Redirect to="/"/>);
				}
			})
		}
	},[]);

	return <>{ returnedRoute }</>
};

export default ProtectedRoute;