import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Form } from 'react-bootstrap';
import { ResponseResult, Project, ReportHeading, ReportProject } from '../../types';
import { request } from '../../services/Request';
import { startOfYear, endOfYear} from 'date-fns';
import Swal from 'sweetalert2';

function ReportHeadingBalance(){

    const [movements, setMovements] = useState<ReportProject[]>([]);
    const [firstYear, setFirstYear] = useState(2021);
    const [selectedYear, setYear] = useState(firstYear);
    const [toggleExchange, setToggle] = useState(false);


    useEffect(()=>{
        getMovements();
    }, []);

    const getMovements = () => {
        Swal.fire("Movimientos","Buscando...");
        Swal.showLoading();
        let date = new Date();
        date = new Date(date.setFullYear(selectedYear));
        request('/api/movement/list/balance',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setMovements(result.data);
                setToggle(true);
            }
        })
        .catch((error)=>{

        });
    }    

    const report = () => {
        Swal.fire("Movimientos","Enviando correo...");
        Swal.showLoading();
        request('/api/movement/report/balance',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Reporte", "El reporte se ha enviado a su cuenta de correo", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar su reporte", "error");
            }
        })
        .catch((error)=>{

        });
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reporte de saldos
                            </Card.Title>
                            <Row className="align-items-end">
                                <Col xs={12} md={{span:4}}>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                </Col>
                                <Col xs={12} md={{span:1}}>
                                </Col>
                                {
                                    toggleExchange && (
                                        <Col xs={12} md={{span:3}}>
                                            <button className="btn btn-secondary w-100" onClick={report}>
                                                Enviar al correo
                                            </button>
                                        </Col>
                                    )
                                }
                            </Row>
                            {
                                movements.map((item:ReportProject, key:number)=>(
                                    <div key={key}>
                                        <h6><b>{item.name}</b></h6>
                                        <Table striped bordered hover className="mt-3">
                                            <thead>
                                                <tr>
                                                    <th>Rubro</th>
                                                    <th>Monto depositado</th>
                                                    <th>Monto ejercido</th>
                                                    <th>Monto actual</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    item.headings.map((movement:ReportHeading,key:number)=>(
                                                        <React.Fragment key={key}>
                                                            <tr>
                                                                <td>{ movement.heading_name }</td>
                                                                <td className="text-end">$ {(movement.amount_ingress_mxn)?.toFixed(2)}</td>
                                                                <td className="text-end">$ {(movement.amount_egress_mxn)?.toFixed(2)}</td>
                                                                <td className="text-end">$ {  ( (movement.amount_ingress_mxn ? movement.amount_ingress_mxn : 0) - (movement.amount_egress_mxn ? movement.amount_egress_mxn : 0) ).toFixed(2) }</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                ))
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportHeadingBalance;
