import { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Modal, Button } from 'react-bootstrap';
import { User, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function UserList(){

    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<Partial<User>>({});
    const [showRemove, setRemove] = useState(false);

    useEffect(()=>{
        getUsers();
    },[]);

    const getUsers = () => {
        request('/api/user?search={}',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setUsers(result.data);
            }
        })
    }

    const toggle = (user:User,status:any) => {
        Swal.fire("Usuarios","Cambiando el estatus");
        Swal.showLoading();
        request('/api/user/toggle/' + user._id,{
            method: 'PUT',
            body: JSON.stringify(status)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                Swal.close();
                getUsers();
            }else{
                Swal.fire("Usuarios", "Ocurrio un error al intentar cambiar el estatus del usuario", "error");
            }
        })
    }

    const confirmRemove = (user:User) => {    
        setSelectedUser(user);
        setRemove(true);
    }

    const removeUser = () => {
        setRemove(false);
        Swal.fire("Usuario","Eliminando usuario...");
        Swal.showLoading();
        request('/api/user/'+selectedUser._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getUsers();
            }else{
                Swal.fire("Usuario", result.error.message.error ,"error");
            }
        })
    }
    const status = (status:Boolean) => {
        if(status){
            return "Activo"
        }else{
            return "Inactivo"
        }
    }

    return (
        <div id="users-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Usuarios
                            </Card.Title>
                            <a className="btn btn-primary float-end mt-3 mb-3" href="/users/new">
                                Nuevo
                            </a>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Correo</th>
                                        <th>Rol</th>
                                        <th>Estatus</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((user:User,key:number)=>(                                    
                                            <tr key={key}>
                                                <td>{user.name+" "+user.lastname}</td>
                                                <td>{user.email}</td>
                                                <td>{user.role}</td>
                                                <td>{status(user.status)}</td>
                                                <td>
                                                    <div className="table-options">
                                                        <a href={"/users/edit/"+user._id} className="btn btn-secondary">
                                                            Editar
                                                        </a>
                                                        <button onClick={()=>{confirmRemove(user)}} className="btn btn-danger ms-2">
                                                            Eliminar
                                                        </button>
                                                        {
                                                            user.status && (
                                                                <button onClick={()=>{toggle(user,{status:false})}} className="btn btn-secondary ms-2">
                                                                    Desactivar
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            !user.status && (
                                                                <button onClick={()=>{toggle(user,{status:true})}} className="btn btn-secondary ms-2">
                                                                    Activar
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el usuario {selectedUser?.name+" "+selectedUser?.lastname}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeUser}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserList;