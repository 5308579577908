export const request = (url:string, options:any) => {
    function handleErrors(response:any) {
        if(response.status === 403 && !options.noRedirect){
          delete localStorage.user;
          delete localStorage.token;
          window.location.href = "/";
        }else if (!response.ok) {
            return response.text().then((text:any) => {
              try{
                response.message = JSON.parse(text);
              }catch(error){
                response.message = text;
              }
              throw response;
            });
        }
        return response;
    }
    if(!options.headers){
      options['headers'] = {
        'Authorization' : "Bearer "+localStorage.token
      }
    }else if(!options.headers['Authorization']){
      options['headers']['Authorization'] = "Bearer "+localStorage.token;
    }
    if(options['method'] === 'POST' && !options["file"]){
      options["headers"]["Content-Type"] = "application/json";
    }
    if((options['method'] === 'DELETE' || options['method'] === 'PUT')&& !options["file"]){
      options["headers"]["Content-Type"] = "application/json";
    }
  
    return fetch(url, options)
    .then( handleErrors)
    .then( (response) => { return response.json() })
    .then(
      (result) => {
        return { data: result };
      }
    )
    .catch(
      (error) => {
        let blocked_urls:any = [
          '/api/auth/login',
        ];
        return { error: error };
      }
    )
  }

  
  export const refreshToken = () => {
  
    function handleErrors(response:any) {
        if(!response.ok) {
          //console.log(response);
          return response.text().then((text:any) => {
            response.message = text
            throw response;
          })
        }
        return response;
    }
    
    let options:any = { headers:{}, method:'GET'};
    options['headers'] = {
      'Authorization' : "Bearer "+localStorage.token
    }
  
    return fetch('/server/services/auth/me', options)
    .then( handleErrors)
    .then( (response) => { return response.json() })
    .then(
      (result) => {
        localStorage.token = result.token;
        return result;
      }
    )
    .catch(
      (error) => {
        console.log(error, error.error);
      }
    )
  }
