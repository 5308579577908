import React, {useEffect, useState } from 'react';
import { Card, Button, Form } from "react-bootstrap";
import { ResponseResult, Heading,  Budget, BudgetMovement } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';

function BudgetMovementNew({id}:{id:string}){

    const [budgets, setBudget] = useState<Budget[]>([]);    
    const [budgetMovement, setBudgetMovement] = useState<Partial<BudgetMovement>>({});
    const [headingData, setHeadingData] = useState<Partial<Heading>>({});
    const [assignBudgets, setAssignBudgets] = useState<Partial<BudgetMovement>>([]);

    useEffect(()=>{
        getAssignBudgets();
        getHeading();
        getBudgets();
    },[]);

    const getAssignBudgets = () => {
        request('/api/budget/budgetsassign/'+id,{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{
            if(result.data){ 
                setAssignBudgets(result.data);
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al cargar los presupuestos");
            }
        })
    }
    
    const getBudgets = () => {
        request('/api/budget',{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{
            if(result.data){      
                setBudget(result.data);
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al cargar los presupuestos");
            }
        })
    }

    const getConceptsAvailable = () => {
        const dataBudgets = budgets;
        const dataAssignBudgets = assignBudgets;
        const elements:any = [];
        for(let j = 0; dataBudgets.length > j ; j++){
            let index = -1;
            for(let k = 0; dataAssignBudgets.length > k; k++){
                if(dataBudgets[j]._id == dataAssignBudgets[k].budget._id){
                    index = k;
                }
            }
            if(index < 0){
                elements.push(
                    <React.Fragment>
                        <option value={dataBudgets[j]._id}>{dataBudgets[j].concept}</option>
                    </React.Fragment>
                );
            }
        }
        return elements;
    }

    const getHeading = () => {
        request('/api/heading/'+id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setHeadingData(result.data);
            }else{
                Swal.fire("Rubro", "Ocurrió un error al cargar el rubro", "error");
            }
        })
    }

    const onChange = (e:any)=>{
        const data = budgetMovement;
        data[e.target.name] = e.target.value;
        setBudgetMovement(data);
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        budgetMovement.heading = id;
        budgetMovement.project = headingData.project;
        request('/api/budget/newbudgetmovement',{
            method: 'POST',
            body: JSON.stringify(budgetMovement)
        })
        .then((result:ResponseResult)=>{
            if(result.data){      
                Swal.fire("Asignar Concepto", "El concepto fue asignado exitosamente", "success");
                window.location.reload();
            }else{
                Swal.fire("Asignar Concepto", "Ocurrio un error mientras se asignaba el concepto", "error");
            }
        })
    }
    return (
        <div className="new-expense">
            <Card>
                <Card.Body>
                    <Card.Title>Agregar concepto</Card.Title>
                        <Form onSubmit={onSubmit}>
                            <Form.Group className="mb-3" controlId="budget">
                                <Form.Label>Concepto</Form.Label>
                                <Form.Select name="budget" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onChange(e)} required>
                                    <option></option>
                                    {
                                        getConceptsAvailable()
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Button variant="primary" type="submit" className="ms-2 mt-4">
                                Guardar
                            </Button>
                        </Form>
                </Card.Body>
            </Card>
        </div>
    );

}

export default BudgetMovementNew;