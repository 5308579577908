import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Heading, ResponseResult, RouteParam } from '../types';
import { useParams } from 'react-router-dom';
import { request } from '../services/Request';
import Swal from 'sweetalert2';

function HeadingEdit({data}:{data:Partial<Heading>}){


    const [headingData, setHeadingData] = useState<Partial<Heading>>({});
    const { id } = useParams<RouteParam>();

    useEffect(()=>{
        request('/api/heading/'+data._id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setHeadingData(result.data);
            }else{
                Swal.fire("Rubro", "Ocurrió un error al cargar el rubro", "error");
            }
        })
    },[data]);

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if( !headingData['name'] ){
            Swal.fire("Rubro", "Es necesario completar todos los campos", "error");
        }else{
            Swal.fire("Rubro", "Guardando registro...");
            Swal.showLoading();
            request("/api/heading/"+data._id,{
                method: 'PUT',
                body: JSON.stringify(headingData)
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    window.location.reload();
                }else if(result.error.status == 420){
                    Swal.fire("Rubro", "El rubro ya existe", "error");
                }else{
                    Swal.fire("Rubro", "Ocurrió un error al guardar el rubro", "error");
                }
            })
            .catch((error)=>{
                Swal.fire("Rubro", "Ocurrió un error al guardar el rubro", "error");
            })
        }
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        let data = headingData;
        data[e.target.name] = e.target.value;
        setHeadingData({...headingData,...data});
    }

    return (
        <div id="projects-form">
            <Card>
                <Card.Body>
                    <Card.Title>Editar rubro</Card.Title>
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" defaultValue={headingData.name} placeholder="" name="name" onChange={onChange}/>
                        </Form.Group>
                        <Row className="mt-5">
                            <Col xs={12} md={2}>
                                <Button variant="primary" type="submit" className="w-100">
                                    Editar rubro
                                </Button>
                            </Col>
                            <Col xs={12} md={2}>
                                <a className="ml-3 btn btn-danger w-100" href="/projects">
                                    Cancelar
                                </a>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default HeadingEdit;
