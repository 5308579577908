import React, { useState } from 'react';
import {Button, Form, Card } from "react-bootstrap";
import Swal from 'sweetalert2';
import { ResponseResult } from '../types';
import { request } from '../services/Request';
import '../styles/headings.scss'

interface Props {
    project? : string,
}
function HeadingNew(props : Props){
    const { project } = props;    

    const [formData, setFormData]:any = useState({
        name:'',
        project
    });

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        request('/api/heading',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.reload();
            }else{
                Swal.fire("Nuevo rubro", "Ocurrió un error al agregar el rubro");
            }
        }).catch((_error:any)=>{
            Swal.fire("Nuevo rubro", "Ocurrió un error al agregar el rubro");
        })
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }


    return (
        <div className="new-heading">
            <Card>
                <Card.Body>
                    <Card.Title>Agregar rubro</Card.Title>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Título del rubro:</Form.Label>
                            <Form.Control type="name" name="name" placeholder="" onChange={onChange} />
                        </Form.Group>

                        <div className="d-flex">
                            <Button variant="primary" type="submit" className="ms-2 mt-4">
                                Agregar rubro
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default HeadingNew;