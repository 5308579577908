import {useEffect, useState } from 'react';
import { Table, Modal, Button, Card, Row, Col } from "react-bootstrap";
import { Project, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';


function ProjectList(){

    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<Partial<Project>>({});
    const [showRemove, setRemove] = useState(false);

    useEffect( () => {
        getProjects();
    }, []);

    const getProjects = () => {
        Swal.fire("Proyectos", "Cargando proyectos...");
        Swal.showLoading();
        request('/api/project',{})
        .then((result:ResponseResult)=>{
            if(result.data){
            setProjects(result.data);
            Swal.close();
            }
        })
    }

    const toggle = (project:Project, status:any) => {
        Swal.fire("Proyectos","Cambiando el estatus");
        Swal.showLoading();
        request('/api/project/toggle/' + project._id,{
            method: 'PUT',
            body: JSON.stringify(status)
        })
        .then((result:ResponseResult)=>{
        if(result.data){
            Swal.close();
            getProjects();
        }else{
            Swal.fire("Proyectos", "Hubo un error al intentar cambiar el estatus del proyecto", "error")
        }
        })
    }

    const confirmRemove = (project:Project) => {
        setSelectedProject(project);
        setRemove(true);
    } 

    const removeProject = () => {
        setRemove(false);
        Swal.fire("Proyecto","Eliminando proyecto...");
        Swal.showLoading();
        request('/api/project/'+selectedProject._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getProjects();
            }else{
                Swal.fire("Proyectos", result.error.message.error ,"error");
            }
        })
    }
    const status = (status:Boolean) => {
        if(status){
            return "Activo"
        }else{
            return "Inactivo"
        }
    }

     return (
        <div className="projectlist-container">
            <br/>
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lista de proyectos</Card.Title>
                            <a className="btn btn-primary float-end mt-3 mb-3" href="/projects/new">
                                Agregar proyecto
                            </a>
                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Nombre del proyecto</th>
                                        <th>Código del proyecto</th>
                                        <th>Estatus</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        projects.map((project:Project, key:number) => (

                                            <tr key={key}>
                                                <td>{project.name}</td>
                                                <td>{project.code}</td>
                                                <td>{status(project.status)}</td>
                                                <td>
                                                    <div className="table-options">
                                                        <a href={"/projects/edit/"+project._id} className="btn btn-secondary ms-2 mt-1">
                                                            Editar
                                                        </a>
                                                        <button onClick={() =>{confirmRemove(project)}} className="btn btn-danger ms-2 mt-1">
                                                            Eliminar
                                                        </button>
                                                        {
                                                            project.status &&(
                                                                <button onClick={() =>{toggle(project,{status:false})}} className="btn btn-secondary ms-2 mt-1">
                                                                    Desactivar
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            !project.status &&(
                                                                <button onClick={() =>{toggle(project,{status:true})}} className="btn btn-secondary ms-2 mt-1">
                                                                    Activar
                                                                </button>
                                                            )
                                                        }

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar proyecto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el proyecto {selectedProject?.name+" "+selectedProject?.code}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeProject}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default ProjectList;