import React, {useState, useEffect, useContext} from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../styles/header.scss';
import {request} from '../services/Request';
import { ResponseResult } from "../types";
import Logo from '../assets/logo.png';
import ConfigContext from '../context/config.context';

function Header(){

    const [isLogged, setLogged] = useState(false);
    const [role, setRole] = useState('');
    const { menu: config, logo, report } = useContext(ConfigContext);

    useEffect(()=>{
        request("/api/auth/me",{
            noRedirect:true
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setLogged(true);
                setRole(result.data.role);
            }
        })
    },[]);

    const logout = () => {
        delete localStorage.token;
        window.location.href = "/";
    }

    return (
        <div id="header">  
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <img src={logo} className="header-logo"></img>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="menu" />
                    <Navbar.Collapse id="menu">
                        <Nav className="ms-auto">
                            {
                                isLogged && (
                                    <React.Fragment>
                                        {
                                            role == 'Administrador' && (
                                                <React.Fragment>                                                
                                                    <Nav.Link href="/accounts">Cuentas</Nav.Link>
                                                    <Nav.Link href="/projects">Proyectos</Nav.Link>
                                                    <Nav.Link href="/movements">Movimientos</Nav.Link>
                                                    {
                                                        config.reports && (
                                                            <NavDropdown title="Reportes" id="reports">
                                                                <NavDropdown.Item href="/reports/movements">Movimientos</NavDropdown.Item>
                                                                <NavDropdown.Divider />
                                                                {
                                                                    report.yearly ? (
                                                                        <NavDropdown.Item href="/reports/year">Mensual</NavDropdown.Item>
                                                                    ) : (
                                                                        <>
                                                                            <NavDropdown.Item href="/reports/headings/month">Rubros - Mensual</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/reports/accounts/month">Cuentas - Mensual</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/reports/headings/history">Rubros - Histórico</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/reports/headings/balance">Rubros - Saldos</NavDropdown.Item>
                                                                        </>
                                                                    )
                                                                }
                                                            </NavDropdown>
                                                        )
                                                    }
                                                    {
                                                        config.expenses && (
                                                            <React.Fragment>
                                                                <Nav.Link href="/expenseReport">Informe de gastos</Nav.Link>
                                                                <Nav.Link href="/myexpenses">Mis informes</Nav.Link>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    {
                                                        config.reports && (                                                        
                                                            <Nav.Link href="/budget">Presupuestos</Nav.Link>
                                                        )
                                                    }
                                                    {
                                                        config.reports && (                                                            
                                                            <Nav.Link href="/users">Usuarios</Nav.Link>
                                                        )
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            role == 'Usuario' && (
                                                <React.Fragment>                                                                                                    
                                                    <Nav.Link href="/myexpenses">Mis informes</Nav.Link>
                                                </React.Fragment>
                                            )
                                        }
                                        <NavDropdown title="Opciones" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="/changepassword">Cambiar contraseña</NavDropdown.Item>
                                            <NavDropdown.Item onClick={logout}>Cerrar sesión</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.FRONT_HOST + "/ayuda"}>Ayuda</NavDropdown.Item>
                                        </NavDropdown>
                                    </React.Fragment>
                                )
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header;