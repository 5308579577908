import React from 'react';
import { Table } from 'react-bootstrap';
import { MovementList, Invoice } from '../types';
import { format, addDays } from 'date-fns';

function MovementTable({movements, totalProjects, options=false, confirmRemove}:{movements:MovementList[], totalProjects:number, options?:boolean, confirmRemove?:any}){

    const showHeadColumns = () => {
        let elements:any = [];
        for(let i = 0; i< totalProjects;i++){
            elements.push(
                <React.Fragment>
                    <th>Proyecto</th>
                    <th>Rubro</th>
                    <th>Parcial</th>
                </React.Fragment>
            )
        }
        return elements
    }

    const showProjectColumns = (movement:MovementList) => {
        let elements:any = [];
        for(let i = 0; i< totalProjects; i++){
            if( movement.projects[i] ){
                elements.push(
                    <React.Fragment>
                        <td>{movement.projects[i].project.name}</td>
                        <td>{movement.projects[i].heading.name}</td>
                        <td className="text-end">$ {(movement.projects[i].amount)?.toFixed(2)}</td>
                    </React.Fragment>
                )
            }else{
                elements.push(
                    <React.Fragment>
                        <td></td>
                        <td></td>
                        <td></td>
                    </React.Fragment>
                )
            }
        }
        return elements;
    }
    return (
        <Table striped bordered hover responsive className="mt-3">
            <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Cuenta</th>
                    <th>Fecha</th>
                    <th>Concepto</th>
                    <th>Monto</th>
                    {
                        showHeadColumns()
                    }
                    <th>Documento/Folio</th>
                    {
                        options && (
                            <th>Opciones</th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    movements.map((movement:MovementList,key:number)=>(
                        <tr key={key}>
                            <td>{ movement.type }</td>
                            <td>{ movement.account.name }</td>
                            <td>{ format(addDays(new Date(movement.date), 1), 'dd/MM/yyyy')}</td>
                            <td>{ movement.concept }</td>
                            <td className="text-end"> $ { (movement.amount).toFixed(2) }</td>
                            {
                                showProjectColumns(movement)
                            }
                            <td>
                                <ul>
                                    {
                                        movement.invoices.map((invoice:Invoice,key:number)=>(
                                            <li>
                                                <a href={"/api/movement/file/"+invoice._id} target="_blank">Ver archivo</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </td>
                            {
                                options && (
                                    <td>
                                        <a href={"/movements/edit/"+movement._id} className="btn btn-secondary">Editar</a>
                                        <button onClick={() => confirmRemove(movement) } className="btn btn-danger">Eliminar</button>
                                    </td>
                                )
                            }
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

export default MovementTable;