import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { ExpenseReport, ResponseResult, RouteParam, User } from '../../types';
import { useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ExpenseReportEdit(){    
    const { id } = useParams<RouteParam>();
    const [formData, setFormData] = useState<Partial<ExpenseReport>>({});

    const getExpenseReport = () => {
        Swal.fire("Informe", "Cargando Informe...");
        Swal.showLoading();
        request('/api/expensereport/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                setFormData(result.data);                
            }else{
                Swal.fire("Informe", "Ocurrió un error al cargar el informe", "error");
            }
        })
    }
    useEffect(()=>{
        getExpenseReport()
    },[]);

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();        
        if(!formData['user'] || !formData['viatic_delivered']){
            Swal.fire('Informe', 'Es necesario completar todos los campos','error');
        }else{                        
            Swal.fire("Informe", "Guardando informe...");
            Swal.showLoading();
            request('/api/expensereport/'+id,{
                method: 'PUT',                
                body: JSON.stringify(formData)
                
            })
            .then((result:ResponseResult)=>{ 
                Swal.close();               
                if(result.data){
                    window.location.href = "/expensereport";
                }else if(result.error.status === 420){
                    Swal.fire("Informe", "El informe ya existe", "error");
                }else{
                    Swal.fire("Nuevo informe", "Ocurrió un error al editar el informe");
                }
            })
        }                       
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>)=>{   
        let data = formData;          
        if(e.target.name == 'viatic_delivered' && Number(e.target.value) < 0){
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            data[e.target.name] = 0;
        }else{
            data[e.target.name] = e.target.value;        
        }
        setFormData({...formData,...data});
    }
       
    return (
        <div id="ExpenseReports-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Editar informe de gastos</Card.Title>
                            <Form onSubmit={submit}>                                
                                <Form.Group controlId="user">
                                    <Form.Label>Nombre del comisionado:</Form.Label>
                                </Form.Group> 
                                <Form.Group  className="mb-3" controlId="user">
                                    <Form.Label>{formData.user?.name + " " + formData.user?.lastname}</Form.Label>
                                </Form.Group>                                 
                                <Form.Group className="mb-3" controlId="viaticDelivered">
                                    <Form.Label>Viáticos entregados:</Form.Label>
                                    <Form.Control required type="number" step="0.01" name="viatic_delivered" placeholder="" disabled={formData.status !== 'Borrador'} onChange={onChange} defaultValue={formData.viatic_delivered} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="destiny">
                                    <Form.Label>Destino:</Form.Label>
                                    <Form.Control required type="text" name="destiny" placeholder="" disabled={formData.status !== 'Borrador'} onChange={onChange} defaultValue={formData.destiny}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodOf">
                                    <Form.Label>Periodo de:</Form.Label>
                                    <Form.Control required type="date" name="period_of" placeholder="" disabled={formData.status !== 'Borrador'} onChange={onChange} defaultValue={formData.period_of?.split('T')[0]}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodTo">
                                    <Form.Label>Hasta:</Form.Label>
                                    <Form.Control required type="date" name="period_to" placeholder="" disabled={formData.status !== 'Borrador'} onChange={onChange} defaultValue={formData.period_to?.split('T')[0]}/>
                                </Form.Group>
                                <Row className="mt-5">
                                    <Col xs={12} md={2}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Editar informe
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a className="ml-3 btn btn-danger w-100" href="/expensereport">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>                   
                </Col>
            </Row>
        </div>
    )
}

export default ExpenseReportEdit;