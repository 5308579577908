import {useEffect, useState } from 'react';
import {Heading, ResponseResult } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import '../styles/headings.scss';
import {Table, Modal, Button, Card} from "react-bootstrap";
import HeadingEdit from './HeadingEdit';

function HeadingList({project}:{project:string}){

    const [headings, setHeadings] = useState<Heading[]>([]);
    const [selectedHeading, setSelectedHeading] = useState<Partial<Heading>>({});
    const [showRemove, setRemove] = useState(false);
    const [editData, setEditData] = useState<Partial<Heading>>({});
    

    useEffect( () => {
        getHeadings();
      }, []);

      const getHeadings = () => {
        request('/api/heading/list/'+project,{})
        .then((result:ResponseResult)=>{
           if(result.data){
            console.log(result);
            setHeadings(result.data);
           }
        })
      }
      const confirmRemove = (heading:Heading) => {
        setSelectedHeading(heading);
        setRemove(true);
      } 

      const removeHeading = () => {
        setRemove(false);
        Swal.fire("Rubro","Eliminando rubro...");
        Swal.showLoading();
        request('/api/heading/'+selectedHeading._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getHeadings();
            }else{
                Swal.fire("Rubros", result.error.message.error ,"error");
            }
        })
      }

      const showEdit = (heading : Heading) => {
        setEditData(heading); 
        Swal.fire("Editar rubro", "Cargando rubro a editar");
        Swal.showLoading();
        setTimeout(()=>{
            const element = document.getElementById("headingEdit");
            element?.scrollIntoView({block: "start"}) as unknown as HTMLElement;
            Swal.close();
        },1000);
      }

    return (
        <div className="new-heading">
            {editData.name != null &&
                <div className="mt-5 mb-5" id="headingEdit">
                    <HeadingEdit data={editData} />
                </div>
            }

            <Card>
                <Card.Body>
                    <Card.Title>Lista de rubros</Card.Title>
                    <Table striped bordered hover size='sm' className="bg-light text-dark">
                        <thead>
                            <tr>
                                <th>Título del rubro</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                                {
                                    headings.map((heading:Heading, key:number) => (

                                        <tr key={key}>
                                            <td>{heading.name}</td>
                                            <td>
                                                <div className="table-options">
                                                    <button onClick={() =>{showEdit(heading)}} className="btn btn-primary ms-2 mt-1">
                                                        Editar
                                                    </button>

                                                    <button onClick={() =>{confirmRemove(heading)}} className="btn btn-danger ms-2 mt-1">
                                                        Eliminar
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>


            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar rubro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el rubro {selectedHeading?.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeHeading}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default HeadingList;