import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Form, Button } from 'react-bootstrap';
import { ResponseResult, ReportProject, Project, ReportHeading } from '../../types';
import { request } from '../../services/Request';
import { addDays, startOfYear, endOfYear, millisecondsToSeconds} from 'date-fns';
import Swal from 'sweetalert2';

function ReportHeadingHistory(){

    const [movements, setMovements] = useState<ReportProject[]>([]);
    const [selectedProject, setProject] = useState('');    
    const [firstYear, setFirstYear] = useState(2021);
    const [selectedYear, setYear] = useState(firstYear);
    const [headerTitles, setHeaderTitles]:any = useState([]);
    const [headers, setHeaders]:any = useState([]); 
    const [currencyType, setCurrencyType] = useState('MXN');
    const [status, setStatus] = useState(true);
    const [toggleExchange, setToggle] = useState(false);

    const months:any = {
        1:'Enero',
        2:'Febrero',
        3:'Marzo',
        4:'Abril',
        5:'Mayo',
        6:'Junio',
        7:'Julio',
        8:'Agosto',
        9:'Septiembre',
        10:'Octubre',
        11:'Noviembre',
        12:'Diciembre'
    }
    const getMovements = () => {
        Swal.fire("Movimientos","Buscando...");
        Swal.showLoading();
        let date = new Date();
        date = new Date(date.setFullYear(selectedYear));
        request('/api/movement/list/history?start='+( startOfYear( date ).toISOString() )+'&end='+( endOfYear( date ).toISOString()),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setMovements(result.data.data);
                setHeaderTitles(result.data.months);                
                const headers:JSX.Element[] = [];                          
                result.data.months.map((numMonth:number)=>(                    
                    headers.push(<th>{ months[numMonth] }</th>)
                ));
                setHeaders(headers);
                setToggle(true);
            }
        })
    }    

    const report = () => {
        Swal.fire("Movimientos","Enviando correo...");
        Swal.showLoading();
        let date = new Date();
        date = new Date(date.setFullYear(selectedYear));
        request('/api/movement/report/history?start='+( startOfYear( date ).toISOString() )+'&end='+( endOfYear( date ).toISOString()),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Reporte", "El reporte se ha enviado a su cienta de correo", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar su reporte", "error");
            }
        })       
    }
    
    const onChangeDate = (e:React.ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value as string;    
        setYear( Number(value) );
    }

    const getYears = () => {
        let options:JSX.Element[] = [];
        for(let i = firstYear; i <= new Date().getFullYear(); i++){
            options.push(
                <option value={i}>{i}</option>
            );
        }
        return options;
    }

    const getColumns = (months:any) => {        
        const columns:JSX.Element[] = [];        
        for(let j=0;j < headerTitles.length;j++){ 
            let index = -1;
            for(let i=0;i<months.length;i++){
                if(months[i].month == headerTitles[j]){
                    index = i;
                    columns.push(<td className="text-end">$ { (months[i].amount_mxn.toFixed(2)) }</td>);
                }
            }
            if(index == -1){
                columns.push(<td className="text-end">$ 0</td>);
            }
        }
        return columns;
    }

    const changeToDollar = () =>{
        const data = [...movements];       
        for(let k = 0; k< data.length; k++){
                const dollarValue = data[k].dollar_value > 0 ? data[k].dollar_value : 1; 
                for(let i = 0; i<data[k].headings.length; i++){
                    data[k].headings[i].amount_mxn = data[k].headings[i].amount_mxn / dollarValue;
                    if(data[k].headings[i].months.length > 0){
                        for(let j= 0; j<data[k].headings[i].months.length; j++){
                            data[k].headings[i].months[j].amount_mxn = data[k].headings[i].months[j].amount_mxn / dollarValue;
                        }
                    }
                }
            }
            console.log(data);
            setMovements(data);               
            setStatus(false);
            setCurrencyType('USD');              
    }
    const changeToPesos = () =>{        
        let data = [...movements];
            for(let j = 0; j< data.length; j++){
                const dollarValue = data[j].dollar_value > 0 ? data[j].dollar_value : 1; 
                for(let i = 0; i<data[j].headings.length; i++){
                    data[j].headings[i].amount_mxn = data[j].headings[i].amount_mxn * dollarValue;
                    if(data[j].headings[i].months.length > 0){
                        for(let k= 0; k<data[j].headings[i].months.length; k++){
                            data[j].headings[i].months[k].amount_mxn = data[j].headings[i].months[k].amount_mxn * dollarValue;
                        }
                    }
                }     
            }
            setMovements(data);
            setStatus(true);
            setCurrencyType('MXN');
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reporte histórico de proyecto
                            </Card.Title>                                        
                            
                            <Row className="align-items-end">
                                <Col xs={12} md={{span:4}}>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group  controlId="year">
                                        <Form.Label>Año</Form.Label>
                                        <Form.Select name="year" onChange={onChangeDate} required>
                                            <option></option>
                                            {
                                                getYears()
                                            }                                            
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:2}}>
                                    <Button className="btn-primary w-100 mt-2" onClick={getMovements}>
                                        Buscar
                                    </Button>
                                </Col>
                                {
                                    toggleExchange && (
                                        <Col xs={12} md={{span:2}}>
                                            <button className="btn btn-secondary w-100" onClick={report}>
                                                Enviar al correo
                                            </button>
                                        </Col>
                                    )
                                }                               
                            </Row>
                            {
                                movements.length > 0 && (
                                    <div className="mt-5">
                                        <Row className="align-items-center">
                                            <Col xs={12} md={{span:3}}>                                    
                                                <Card.Text className="position-relative text-left text-primary mb-2">Moneda actual: {currencyType} </Card.Text>
                                            </Col>
                                            {
                                                status && (
                                                    <Col xs={12} md={{span:3}}>
                                                        <Button className="btn-success w-100 mt-2" onClick={changeToDollar}>
                                                            Convertir a dolares
                                                        </Button>
                                                    </Col>

                                                )
                                            }
                                            {
                                                !status && (
                                                    <Col xs={12} md={{span:3}}>
                                                        <Button className="btn-success w-100 mt-2" onClick={changeToPesos}>
                                                            Convertir a pesos
                                                        </Button> 
                                                    </Col>
                                                )
                                            }                          
                                                                            
                                        </Row>
                                    </div>                            
                                )
                            }
                            {
                                movements.map((project:ReportProject, key:number)=>(
                                    <div key={key} className="mt-5">
                                        <h5><b>{project.name}</b></h5>
                                        {
                                            project.dollar_value > 1 && (
                                                <div>
                                                    <Card.Text className="position-relative text-left text-success mb-2">Precio del dolar: ${(project.dollar_value)?.toFixed(2)}</Card.Text>
                                                </div>
                                            )
                                        }
                                        <Table striped bordered hover className="mt-3">
                                            <thead>
                                                <tr>
                                                    <th>Rubro</th>
                                                    <th>Gastos acumulados</th>
                                                    {
                                                        headers
                                                    }
                                                    <th>Suma del año</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    project.headings.map((movement:ReportHeading,subkey:number)=>(
                                                        <React.Fragment key={key+"-"+subkey}>
                                                            <tr>
                                                                <td>{ movement.heading_name }</td>
                                                                <td className="text-end">$ {(movement.heading_previous_amount).toFixed(2)}</td>
                                                                {
                                                                    getColumns(movement.months)
                                                                }
                                                                <td className="text-end">$ {(movement.amount_mxn).toFixed(2)}</td>
                                                                <td className="text-end">$ {(movement.heading_previous_amount + movement.amount_mxn).toFixed(2)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                ))
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportHeadingHistory;
