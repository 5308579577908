import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';
import { ResponseResult, MovementList, Account, Project } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { subDays } from 'date-fns';
import MovementTable from '../../components/MovementTable';

function MovementsList (){

    const [movements, setMovements] = useState<MovementList[]>([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [selectedMovement, setSelectedMovement] = useState<Partial<MovementList>>({});
    const [showRemove, setRemove] = useState(false);
    const [start, setStart] = useState<Date>();
    const [end, setEnd] = useState<Date>();
    const [text, setText] = useState('');
    const [account, setAccount] = useState();
    const [project, setProject] = useState();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [amount, setAmount] = useState('')
    const [movementType, setMovementType] = useState('')

    useEffect(()=>{
          getAccounts();
        getProjects();
        const startMonth = new Date( subDays(new Date(),30));
        const endMonth = new Date();
        Swal.fire("Movimientos","Cargando...");
        Swal.showLoading();
        request('/api/movement?start='+(startMonth.toISOString())+'&end='+(endMonth.toISOString()),{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setMovements(result.data.data);
                setTotalProjects(result.data.total_projects);
                Swal.close();
            }else{
                setMovements([]);
            }
            Swal.close();
        })
    },[]);

    const getAccounts = () => {
        request('/api/accounts',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setAccounts(result.data);
            }
        })
    }

    const getProjects = () => {
        request('/api/project',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setProjects(result.data);
            }
        })
    }

    const getMovements = () => {
        Swal.fire("Movimientos","Buscando...");
        Swal.showLoading();
        request('/api/movement?project='+project+'&amount=' + amount + '&type=' + movementType + '&account='+account+'&text='+text+'&start='+(start ? start.toISOString() : '')+'&end='+( end ? new Date(end).toISOString() : ''),{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setMovements(result.data.data);
                setTotalProjects(result.data.total_projects);
            }else{
                setMovements([]);
            }
            Swal.close();
        })
    }

    const confirmRemove = (movement:MovementList) => {
        setSelectedMovement(movement);
        setRemove(true);
    }

    const removeMovement = () => {
        setRemove(false);
        Swal.fire("Movimiento","Eliminando movimiento...");
        Swal.showLoading();
        request('/api/movement/'+selectedMovement._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.reload();
            }else{
                Swal.fire("Movimiento","Ocurrió un error al eliminar el movimiento","error");
            }
        })
    }

    const onChange = (e:any) => {
        if(e.target.name === 'start'){
            setStart( new Date(e.target.value) );
        }else if(e.target.name == 'end'){
            setEnd( new Date(e.target.value) );
        }else if(e.target.name === 'text'){
            setText(e.target.value);
        }else if(e.target.name === 'amount'){
            setAmount(e.target.value);
        }else if(e.target.name === 'movementType'){
            setMovementType(e.target.value);
        }else if(e.target.name === 'account'){
            setAccount(e.target.value);
        }else if(e.target.name === 'project'){
            setProject(e.target.value);
        }
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Listado de movimientos
                            </Card.Title>
                            <Row>
                            <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="start">
                                        <Form.Label>Desde</Form.Label>
                                        <Form.Control type="date" placeholder="" name="start" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="end">
                                        <Form.Label>Hasta</Form.Label>
                                        <Form.Control type="date" placeholder="" name="end" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="text">
                                        <Form.Label>Por concepto</Form.Label>
                                        <Form.Control type="input" placeholder="" name="text" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="text">
                                        <Form.Label>Por monto</Form.Label>
                                        <Form.Control type="input" placeholder="" name="amount" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="text">
                                        <Form.Label>Por tipo</Form.Label>
                                        <Form.Select name="movementType" onChange={onChange} required>
                                            <option></option>
                                            <option value="Egreso">Egreso</option>
                                            <option value="Ingreso">Ingreso</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="account">
                                        <Form.Label>Por cuenta</Form.Label>
                                        <Form.Select name="account" onChange={onChange} required>
                                            <option></option>
                                            {
                                                accounts.map((account:Account,key:number)=>(
                                                    <option value={account._id}>{account.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="project">
                                        <Form.Label>Por proyecto</Form.Label>
                                        <Form.Select name="project" onChange={onChange} required>
                                            <option></option>
                                            {
                                                projects.map((project:Project,key:number)=>(
                                                    <option value={project._id}>{project.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:2}}>
                                    <Button className="btn btn-primary w-100 mt-4" onClick={getMovements}>
                                        Buscar
                                    </Button>
                                </Col>
                                <Col>
                                    <a className="btn btn-primary float-end mt-3 mb-3" href="/movements/new">
                                        Agregar movimiento
                                    </a>                            
                                </Col>
                            </Row>
                            <MovementTable options={true} confirmRemove={confirmRemove} movements={movements} totalProjects={totalProjects}></MovementTable>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar movimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el movimiento?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeMovement}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MovementsList;