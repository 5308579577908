import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Account, ResponseResult, RouteParam } from '../../types';
import { useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function AccountEdit(){

    const [accountData, setAccountData] = useState<Partial<Account>>({});
    const { id } = useParams<RouteParam>();

    useEffect(()=>{
        Swal.fire("Cuenta", "Cargando cuenta...");
        Swal.showLoading();
        request('/api/accounts/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setAccountData(result.data);
            }else{
                Swal.fire("Cuenta", "Ocurrió un error al cargar la cuenta", "error");
            }
        })
        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    },[]);

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        Swal.fire("Cuenta", "Guardando registro...");
        Swal.showLoading();
        request("/api/accounts/"+id,{
            method: 'PUT',
            body: JSON.stringify(accountData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.href="/accounts";
            }else{
                Swal.fire("Cuenta", "Ocurrió un error al guardar la cuenta", "error");
            }
        })
    }

    const onChange = (e: any)=>{
        const data = accountData;
        data[e.target.name] = e.target.value;
        setAccountData({...accountData,...data});
    }

    return (
        <div id="users-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Nuevo usuario</Card.Title>
                            <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" defaultValue={accountData.name} name="name" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="clabe">
                                    <Form.Label>Clabe</Form.Label>
                                    <Form.Control type="number" defaultValue={accountData.clabe} name="clabe" step="0.01" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="financial_situation">
                                    <Form.Label>Situación Financiera</Form.Label>
                                    <Form.Control type="text" defaultValue={accountData.financial_situation} name="financial_situation" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="balance">
                                    <Form.Label>Saldo</Form.Label>
                                    <Form.Control type="number" defaultValue={accountData.balance} name="balance" step="0.01" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="account">
                                    <Form.Label>Cuenta</Form.Label>
                                    <Form.Control type="number" defaultValue={accountData.account} name="account" step="0.01" onChange={onChange}/>
                                </Form.Group>
                                <Row className="mt-5">
                                    <Col xs={12} md={2}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Guardar
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a className="ml-3 btn btn-danger w-100" href="/accounts">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AccountEdit;