import React, { useState, useEffect } from 'react';
import {Button, Table, Card, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import { ResponseResult, Budget, BudgetHeading, RouteParam} from '../../types';
import { request } from '../../services/Request';
import { useParams } from 'react-router-dom';
import { getConstantValue } from 'typescript';

function BudgetDetail(){

    const { id } = useParams<RouteParam>();
    const [budgetData, setBudgetData] = useState<Partial<Budget>>({
        mount_month: 1
    });
    const [headings, setHeadings] = useState<BudgetHeading[]>([]);

    const getBudget = () => {
        Swal.fire("Presupuesto", "Cargando presupuesto...");
        Swal.showLoading();
        request('/api/budget/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                setBudgetData(result.data);                
            }else{
                Swal.fire("Informe", "Ocurrió un error al cargar el informe", "error");
            }
        })
    }

    const getHeadings = () => {
        Swal.fire("Presupuesto", "Cargando presupuesto...");
        Swal.showLoading();
        request('/api/budget/headings/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                setHeadings(result.data);                
            }else{
                Swal.fire("Informe", "Ocurrió un error al cargar el informe", "error");
            }
        })
    }
    
    useEffect(()=>{
        getBudget();
        getHeadings();
    }, []);

    const getTotal = () => {
        let total = 0;
        for(let i = 0; i<headings.length; i++){
            total += (headings[i].percent/100) * ( headings[i].ingress - headings[i].egress );
        }
        return total;
    }
    
    return (
        <div id="budget-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Detalle del presupuesto <b>{budgetData.concept}</b></Card.Title>

                            {
                                budgetData && (
                                    <div className="mt-5 mb-5">
                                        <h6><b>Monto Mensual:</b> $ { budgetData.mount_month ? budgetData.mount_month.toFixed(2) : 0.00 }</h6>
                                        <h6><b>Presupuesto total:</b> $ { (getTotal()).toFixed(2) }</h6>
                                        <h6><b>Meses proyectados:</b> { (getTotal() / (budgetData.mount_month ? budgetData.mount_month : 1)).toFixed(2) }</h6>
                                    </div>
                                )
                            }

                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Proyecto</th>
                                        <th>Rubro</th>
                                        <th>USD</th>                                        
                                        <th>MXN</th>
                                        <th>Porcentaje</th> 
                                        <th>Presupuesto</th>                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        headings.map((item:BudgetHeading, key:number)=>(
                                            <tr key={key}>
                                                <td>{ item.project ? item.project.name : 'Sin proyecto' }</td>
                                                <td>{ item.heading ? item.heading.name : 'Sin rubro' }</td>
                                                <td className="text-end">$ { (( item.ingress - item.egress ) / (item.project && item.project.dollar_value ? item.project.dollar_value : 1)).toFixed(2) }</td>
                                                <td className="text-end">$ { ( item.ingress - item.egress ).toFixed(2) }</td>
                                                <td className="text-end">{ item.percent+"%" }</td>
                                                <td className="text-end">$ { ((item.percent/100) * ( item.ingress - item.egress )).toFixed(2) }</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default BudgetDetail;