import HeadingNew from '../../components/HeadingNew';
import HeadingList from '../../components/HeadingList';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Project, ResponseResult, RouteParam } from '../../types';
import { useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ProjectEdit(){

    const [projectData, setProjectData] = useState<Partial<Project>>({});
    const { id } = useParams<RouteParam>();

    useEffect(()=>{
        Swal.fire("Proyecto", "Cargando proyecto...");
        Swal.showLoading();
        request('/api/project/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setProjectData(result.data);
            }else{
                Swal.fire("Proyecto", "Ocurrió un error al cargar el proyecto", "error");
            }
        })
        .catch((error)=>{
            Swal.fire("Proyecto", "Ocurrió un error al cargar el proyecto", "error");
        });
    },[]);

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if( !projectData['name']
        || !projectData['code'] ){
            Swal.fire("Proyecto", "Es necesario completar todos los campos", "error");
        }else{
            Swal.fire("Proyecto", "Guardando registro...");
            Swal.showLoading();
            request("/api/project/"+id,{
                method: 'PUT',
                body: JSON.stringify(projectData)
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    window.location.href="/projects";
                }else if(result.error.status === 420){
                    Swal.fire("Proyecto", "El proyecto ya existe", "error");
                }else{
                    Swal.fire("Proyecto", "Ocurrió un error al guardar el proyecto", "error");
                }
            })
            .catch((error)=>{
                Swal.fire("Proyecto", "Ocurrió un error al guardar el proyecto", "error");
            })
        }
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        let data = projectData;
        data[e.target.name] = e.target.value;
        setProjectData({...projectData,...data});
    }

    return (
        <div id="projects-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Editar proyecto</Card.Title>
                            <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" defaultValue={projectData.name} placeholder="" name="name" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="code">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control type="text" defaultValue={projectData.code} placeholder="" name="code" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="dollar_value">
                                    <Form.Label>Valor del dolar:</Form.Label>
                                    <Form.Control type="number" step="0.01" defaultValue={projectData.dollar_value} name="dollar_value" placeholder="" onChange={onChange} />
                                </Form.Group>
                                <Row className="mt-5">
                                    <Col xs={12} md={2}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Editar proyecto
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a className="ml-3 btn btn-danger w-100" href="/projects">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="mt-5">
                        <HeadingNew project={id} ></HeadingNew>
                    </div>
                    <div className="mt-5">
                        <HeadingList project={id ? id : '1'}></HeadingList>
                    </div>
                    <div>
                        <a href="/projects" className="btn btn-secondary ms-2 mt-4">Regresar</a>    
                    </div>        
                </Col>
            </Row>
        </div>
    )
}

export default ProjectEdit;

