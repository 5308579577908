import React, {useState, useContext} from 'react';
import {Row, Col, Card, Form, Button, Modal} from 'react-bootstrap';
import '../../styles/login.scss';
import { useParams } from 'react-router-dom';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import ConfigContext from '../../context/config.context';

function Login(){

    const [loginData, setLoginData]:any = useState({
        email:'',
        password:'',
        code:''
    });
    const [showRecover, setRecover] = useState(false);
    const [email, setEmail] = useState('');
    const context = useContext(ConfigContext);

    console.log(context);

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        request('/api/auth/signin',{
            method: 'POST',
            body: JSON.stringify(loginData)
        })
        .then((result:ResponseResult)=>{
            if(result.data){    
                localStorage.token = result.data.token;
                if(result.data.role == 'Administrador'){
                    window.location.href = "/projects"; 
                }else{
                    window.location.href = "/myexpenses"; 
                }
            }else if(result.error.status == 420 && result.error.message.type){
                Swal.fire("Iniciar sesión", "El código no es válido o ya ha expirado", "error");
            }else if(result.error.status == 420){
                Swal.fire("Iniciar sesión", "Datos incorrectos", "error");
            }else{
                Swal.fire("Iniciar sesión", "Ocurrió un error al iniciar sesión","error");
            }
        })
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data = loginData;
        data[e.target.name] = e.target.value;
        setLoginData(data);
    }

    const emailHandle = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setEmail(e.target.value);
    }

    const confirmRecover = () => {
        Swal.fire('Enviando petición...');
        Swal.showLoading();
        request('/api/auth/request',{
            method: 'POST',
            body: JSON.stringify({
                email: email
            })
        })
        .then((result:ResponseResult)=>{
            setRecover(false);
            Swal.hideLoading();
            if(result.data){      
                Swal.fire("Recuperar contraseña", 'Se ha realizado la petición con éxito. En unos momentos llegará a tu correo un enlace para continuar el proceso.', 'success');
            }else if(result.error.status == 420){
                Swal.fire("Recuperar contraseña", 'No hay un usuario registrado con ese correo', 'error');
            }else{
                Swal.fire("Recuperar contraseña", 'Ocurrió un error intentado enviar la petición', 'error');
            }
        })
        .catch((error)=>{
            Swal.fire("Recuperar contraseña", 'Ocurrió un error intentado enviar la petición', 'error');
        });
    }

    return (
        <div id="login-container">
            <Row className="w-100">
                <Col xs={12} md={{span:10,offset:1}} lg={{span:6,offset:3}}>
                    <Card>
                        <Card.Body>
                            <img src={context.logo} className="login-logo mb-3"></img>
                            <Card.Title>Iniciar sesión</Card.Title>
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="code">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control type="text" name="code" placeholder="" onChange={onChange}/>
                                </Form.Group>
                                <button type="button" className="mt-3 mb-3 ps-0 btn btn-link" onClick={()=>{setRecover(true)}}>Recuperar contraseña</button>
                                <div className="d-flex">
                                    <Button variant="primary" type="submit">
                                        Ingresar
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showRecover} onHide={()=>{setRecover(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recuperar contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="email">
                        <Form.Label>
                            Ingrese su correo electrónico y enviaremos un correo con la
                            solicitud para la recuperación de contraseña.
                        </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={emailHandle}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRecover(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={confirmRecover}>
                        Solicitar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Login;