import {useEffect, useState } from 'react';
import { Table, Card, Row, Col } from "react-bootstrap";
import { ExpenseReport, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

function MyExpenses(){

    const [expenseReports, setExpenseReports] = useState<ExpenseReport[]>([]);              
    useEffect( () => {            
        getUserId();          
      }, []);  
                
      const getUserId = () => {
        request('/api/auth/me',{})
        .then((result:ResponseResult)=>{
           if(result.data){            
            getExpenseReports(result.data._id);            
           }else{
            Swal.fire("Informe de gastos", "Ocurrió un error al obtener el id del usuario");
           }           
        })
      }       
      const getExpenseReports = (id:string) => {
        Swal.fire("Informes", "Cargando sus informes...");
        Swal.showLoading();
        request('/api/expensereport/myreports/'+ id,{})
        .then((result:ResponseResult)=>{
            if(result.data){            
                setExpenseReports(result.data);
                Swal.close();      
           }else{
            Swal.fire("Nuevo informe de gastos", "Ocurrió un error al mostrar el informe de gastos");
           }
        })
      }
     return (
        <div className="ExpenseReportList-container">
            <br/>
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Mis informes asignados</Card.Title>
                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Destino</th>
                                        <th>Viáticos entregados:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        expenseReports.map((expensereport:ExpenseReport, key:number) => (

                                            <tr key={key}>
                                                <td>{format(new Date(expensereport.date_report), 'dd/MM/yyyy')}</td> 
                                                <td>{expensereport.destiny}</td>  
                                                <td className="text-end">${expensereport.viatic_delivered}</td>                                              
                                                <td>
                                                    <div className="table-options">
                                                        <a href={"/expensereport/expensedetails/"+expensereport._id} className="btn btn-secondary ms-2 mt-1">
                                                            Ver detalle
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default MyExpenses;