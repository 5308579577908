import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Movement, ResponseResult, Project, Account, ProjectMovement, RouteParam } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import {useDropzone} from 'react-dropzone';
import '../../styles/movements.scss';
import { isTemplateTail } from 'typescript';
import { useParams } from 'react-router-dom';

function MovementEdit(){

    const [movementData, setMovementData] = useState<Partial<Movement>>({
        projects: [],
        account: {

        }
    });
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedFiles, setFiles]:any = useState([]);
    const [projectTotals, setProjectTotals]:any = useState({});
    const [accounts, setAccounts] = useState<Account[]>([]);
    const {id} = useParams<RouteParam>();
    const [movementToRemove, setMovementsRemove] = useState([]);

    useEffect(()=>{
        getProjects();
        getMovement();
        getAccounts();
        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    }, []);

    const getMovement = () => {
        request('/api/movement/'+id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                const data = result.data;
                data['account'] = result.data.account._id;
                setMovementData(result.data);
            }
        })
    }

    const getAccounts = () => {
        request('/api/accounts',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setAccounts(result.data);
            }
        })
    }
    
    const onDrop = (acceptedFiles:any) => {
        let files = selectedFiles;
        files = files.concat(acceptedFiles);
        setFiles(files);
    };
    
    const {getRootProps, getInputProps } = useDropzone({multiple:true, onDrop: onDrop});

    const getProjects = () => {
        request('/api/project?search={"status":true}',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setProjects(result.data);
            }
        })
    }

    const getHeadings = ( project:string, index:number ) => {
        request("/api/heading/list/"+project,{
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                //setHeadings(result.data);
                (function checkElement(){
                    let element = document.getElementById(index+"-heading-"+project) as HTMLSelectElement;
                    if(element){
                        var i, L = element.options.length - 1;
                        for(i = L; i >= 0; i--) {
                            element.remove(i);
                        }
                        let option = document.createElement("option");
                            option.text = '';
                            if(element){
                                element.add(option); 
                            }
                        for(let item of result.data){
                            let option = document.createElement("option");
                            option.text = item.name;
                            option.value = item._id;
                            if(element){
                                element.add(option); 
                            }
                        }
                    }else{
                        setTimeout(checkElement, 100);
                    }
                })();
            }
        })        
    }

    const addProject = () => {
        const data:Partial<Movement> = movementData;
        data.projects?.push({
            project:'',
            heading:'',
            amount: 0,
            index: Math.random()
        });
        setMovementData({...movementData,...data});
    }

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        let total = 0;
        if(movementData.projects){
            for(const project of movementData.projects){
                total +=  Number(project.amount);
            }
        }
        console.log(total, movementData.amount);
        if( movementData.projects && movementData.projects.length > 0 && movementData.projects.length > 0 && total.toFixed(2) != Number(movementData.amount).toFixed(2) ){
            Swal.fire('Movimiento','Las cantidades de ingreso en los proyectos no coinciden con el total','error');
        }else{
            const data = new FormData();
            for(let field in movementData){
                if(field == 'projects'){
                    data.append('projects', JSON.stringify( movementData[field] ));
                }else{
                    data.append(field, movementData[field]);
                }
            }
            for(let file of selectedFiles){
                data.append('invoices', file);
            }
            for(let id of movementToRemove){
                data.append('remove[]', id);
            }
            Swal.fire("Movimiento", "Guardando registro...");
            Swal.showLoading();
            request("/api/movement/"+id,{
                method: 'PUT',
                file:true,
                body: data
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    window.location.href="/movements";
                }else if(result.error.status == 420){
                    Swal.fire("Movimiento", "El movimiento ya existe", "error");
                }else{
                    Swal.fire("Movimiento", "Ocurrió un error al guardar el movimiento", "error");
                }
            })
        }
    }

    const onChange = (e:any)=>{
        const data = movementData;
        if(e.target.name == 'amount' && e.target.value < 0){
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            data[e.target.name] = 0;
        }else{
            data[e.target.name] = e.target.value;
        }
        setMovementData(data);
    }

    const onChangeArray = (e:any, index:number)=>{
        const data = movementData;
        if(data['projects']){
            if(data.type === 'Egreso' && e.target.name === 'amount' && projectTotals[index] < Number(e.target.value)){
                Swal.fire("Rubro","La cantidad ingresada excede la disponible en el rubro","error");
            }else{
                if(e.target.name == 'amount' && e.target.value < 0){
                    (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
                    data['projects'][index][e.target.name] = 0;
                }else{
                    data['projects'][index][e.target.name] = e.target.value;
                }
            }
        }
        setMovementData({...movementData, ...data});
        if(e.target.name == 'project'){
            getHeadings( e.target.value, index );
        }
        if(e.target.name == 'heading'){
            getTotal( e.target.value, index  );
        }
    }

    const getTotal = ( heading:string, index:number ) => {
        request("/api/heading/total/"+heading,{
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                const data = projectTotals;
                data[index] = result.data.total
                setProjectTotals({...data});
            }
        })
    }

    const removeProject = (index:number) => {
        const data = movementData;
        if(data.projects){
            if(data.projects[index]._id){
                let movements:any = [...movementToRemove];
                movements.push(data.projects[index]._id);
                setMovementsRemove(movements);
            }
            data.projects.splice(index,1);
        }
        const projects = projectTotals;
        delete projects[index];
        for(let i =index; i < Object.keys(projects).length; i++ ){
            projects[i]=projects[i+1];
        }
        setProjectTotals({...projects});
        setMovementData({...movementData, ...data});
    }

    const removeFile = (index:number) => {
        setFiles( selectedFiles.filter((item:any, key:number) => key !== index) );
    }

    const filterProject = (index:number) => {     
        const data = movementData;
        if(data['projects']){
            let project:any = projects.filter((item)=>{
                return item._id === (data['projects'] ? data['projects'][index]['project'] : '');
            });        
            if(project.length > 0 && project[0].dollar_value){
                return project[0];
            }else{
                return null;
            }      
        }else{
            return null;
        }                
    }

    const getHeadingOptions = (project_movement:ProjectMovement) => {
        if(project_movement._id){
            request("/api/heading/list/"+project_movement.project,{
            })
            .then((result:ResponseResult)=>{
                if(result.data){
                    //setHeadings(result.data);
                    (function checkElement(){
                        let element = document.getElementById(project_movement._id+"-heading-"+project_movement.project) as HTMLSelectElement;
                        if(element){
                            var i, L = element.options.length - 1;
                            for(i = L; i >= 0; i--) {
                                element.remove(i);
                            }
                            let option = document.createElement("option");
                                option.text = '';
                                if(element){
                                    element.add(option); 
                                }
                            for(let item of result.data){
                                let option = document.createElement("option");
                                option.text = item.name;
                                option.value = item._id;
                                if(project_movement.heading == item._id){
                                    option.selected = true;
                                }
                                if(element){
                                    element.add(option); 
                                }
                            }
                        }else{
                            setTimeout(checkElement, 100);
                        }
                    })();
                }
            })  
        }
    }

    return (
        <div id="movements-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Nuevo Movimiento</Card.Title>
                            <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select name="type" onChange={onChange} required>
                                        <option></option>
                                        <option value="Ingreso" selected={ movementData.type === 'Ingreso' }>Ingreso</option>
                                        <option value="Egreso" selected={ movementData.type === 'Egreso' }>Egreso</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="account">
                                    <Form.Label>Método</Form.Label>
                                    <Form.Select name="account" onChange={onChange} required>
                                        <option></option>
                                        {
                                            accounts.map((account:Account,key:number)=>(
                                                <option defaultValue={account._id} selected={ movementData.account === account._id } value={account._id}>{account.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="concept">
                                    <Form.Label>Concepto</Form.Label>
                                    <Form.Control required type="text" placeholder="" name="concept" defaultValue={ movementData.concept } onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="amount">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control required type="number" step="0.01" placeholder="" defaultValue={movementData.amount} name="amount" onChange={onChange}/>
                                </Form.Group> 
                                <Form.Group className="mb-3" controlId="date">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control required type="date" placeholder="" name="date" defaultValue={movementData.date?.split('T')[0]} onChange={onChange}/>
                                </Form.Group> 
                                {
                                    movementData.projects?.map((item:ProjectMovement,key:number)=>(
                                        <React.Fragment key={item.index}>
                                            <hr></hr>
                                            <Form.Group className="mb-3" controlId={"name-"+key}>
                                                <Form.Label>Proyecto</Form.Label>
                                                <Form.Select required name="project" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onChangeArray(e, key)}>
                                                    <option></option>
                                                    {
                                                        projects.map((project:Project,key:number)=>(
                                                            <option value={project._id} selected={ project._id === item.project }>{project.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                            {
                                                (item.project && item.project != '') && (
                                                    <React.Fragment>                                                    
                                                        <Form.Group className="mb-3" controlId={(item._id ? item._id : key)+"-heading-"+item.project}>
                                                            <Form.Label>Rubro</Form.Label>
                                                            <Form.Select required name="heading" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onChangeArray(e, key)}>
                                                                <option></option>  
                                                                { getHeadingOptions(item) }                                                      
                                                            </Form.Select>
                                                        </Form.Group>                          
                                                        {
                                                            movementData.type === 'Egreso' && item.heading && (
                                                                <React.Fragment>
                                                                    <p><b>Cantidad disponible en el rubro:</b> ${projectTotals[key] ? projectTotals[key].toFixed(2) : 0}</p>
                                                                </React.Fragment>
                                                            ) 
                                                        }
                                                        <Form.Group className="mb-3" controlId={"amount-"+item.heading}>
                                                            <Form.Label>Cantidad</Form.Label>
                                                            <Form.Control required type="number" step="0.01" placeholder="" defaultValue={item.amount} name="amount" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChangeArray(e, key)}/>
                                                        </Form.Group>  
                                                                                        
                                                    </React.Fragment>
                                                )
                                            }
                                            <button type="button" className="btn btn-danger mb-5" onClick={()=> removeProject(key)}>
                                                Eliminar proyecto
                                            </button>
                                            <hr></hr>
                                        </React.Fragment>
                                    ))
                                } 
                                <button type="button" className="btn btn-secondary mb-5" onClick={addProject}>
                                    + Agregar proyecto
                                </button>
                                <hr></hr>
                                <div className='file-drop justify-center mt-10' {...getRootProps()}>
                                    <input {...getInputProps({multiple:true})} />
                                    <p className="text-center mb-4">Arrastra y suelta tus archivos o presiona aquí para elegirlos.</p> 
                                </div>
                                <p><b>Archivos seleccionados:</b></p>
                                <ul className="mb-5">
                                    {
                                        selectedFiles.map((file:any,key:number)=>(
                                            <li key={key}>
                                                { file.name } <button type="button" className=" mt-1 mb-1 btn btn-danger" onClick={() => removeFile(key)}>Eliminar</button>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <Row className="mt-5">
                                    <Col xs={12} md={2}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Guardar
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a className="ml-3 btn btn-danger w-100" href="/movement">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default MovementEdit;