import React, { useState } from 'react';
import {Button, Form, Card, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';

function ProjectAdd(){

    const [formData, setFormData]:any = useState({
        name:'',
        code:''
    });

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        request('/api/project',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/projects";
            }else{
                Swal.fire("Nuevo proyecto", "Ocurrió un error al agregar el proyecto");
            }
        }).catch((_error:any)=>{
            Swal.fire("Nuevo proyecto", "Ocurrió un error al agregar el proyecto");
        })
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
        console.log("value",e.target.value);
    }

    return (
        <div id="projectadd-container mb-1">

            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Nuevo proyecto</Card.Title>
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nombre del proyecto:</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="" onChange={onChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="id">
                                    <Form.Label>Código del proyecto:</Form.Label>
                                    <Form.Control type="text" name="code" placeholder="" onChange={onChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="dollar_value">
                                    <Form.Label>Valor del dolar:</Form.Label>
                                    <Form.Control type="number" step="0.01" name="dollar_value" placeholder="" onChange={onChange} />
                                </Form.Group>
                                

                                <div className="d-flex">
                                    <Button variant="primary" type="submit" className="ms-2 mt-4">
                                        Agregar proyecto
                                    </Button>
                                    <a href="/projects" className="btn btn-secondary ms-2 mt-4">Cancelar</a>
                                    <Button variant="danger" type="reset" className="ms-2 mt-4">
                                        Borrar todo
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default ProjectAdd;