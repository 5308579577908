import React, {useEffect, useState } from 'react';
import { Table, Card, Row, Col } from "react-bootstrap";
import { ResponseResult, Project, Heading } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ProjectHeadingList(){
    const [projectsAndheadings, setProjectsAndheadings] = useState<Project[]>([]);         

    useEffect( () => {            
        getProjectHeading();
    }, []);            
     
    const getProjectHeading = () => {
    request('/api/project/listprojectheading',{
        method: 'GET'
    })
    .then((result:ResponseResult)=>{
        if(result.data){
            setProjectsAndheadings(result.data);
        }else{
        Swal.fire("Presupuestos", "Ocurrió un error al cargar la lista de proyectos con sus rubros","error");
        }
    })
    }
    return (        
        <div className="ProjectHeadingList-container">
            <br/>
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lista de proyectos con rubros</Card.Title>
                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Proyectos</th>
                                        <th>Rubros</th>
                                        <th className="w-25"> Porcentaje asignado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        projectsAndheadings.map((project:Project, key:number) => (
                                            <>
                                                {
                                                    project?.headings?.map((heading:Heading, key:number)=>(  
                                                        <tr key={key}>
                                                            <td>{project.name}</td>
                                                            <td>
                                                                <a className="h6" href={'/budget/asignarconcepto/' + heading._id}>{heading.name}</a>
                                                            </td>
                                                            <td className="text-end">
                                                                {heading.percentageAssign}%
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <a href="/budget" className="btn btn-secondary ms-2 mt-4 mb-4">Regresar</a>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}
export default ProjectHeadingList;