import React, {useEffect, useState } from 'react';
import { Table, Modal, Button, Card, Row, Col } from "react-bootstrap";
import { Budget, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

function BudgetList(){
    const [budgets, setBudgets] = useState<Budget[]>([]);    
    const [selectedBudget, setSelectedBudget] = useState<Partial<Budget>>({});
    const [showRemove, setRemove] = useState(false);   

    useEffect( () => {            
        getBudgets();
    }, []);            
    
     
    const getBudgets = () => {
        Swal.fire("Presupuesto", "Cargando presupuestos...");
        Swal.showLoading();
        request('/api/budget',{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{
            if(result.data){            
                setBudgets(result.data);
                Swal.close();
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al cargar los presupuestos");
            }
        })
    }

    const confirmRemove = (Budget:Budget) => {
        setRemove(true);
        setSelectedBudget(Budget);
    } 

    const removeBudget = () => {
        setRemove(false);
        Swal.fire("Presupuestos","Eliminando presupuesto...");
        Swal.showLoading();
        request('/api/budget/'+selectedBudget._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getBudgets();
            }else{
                Swal.fire("Presupuestos","Ocurrió un error al eliminar el presupuesto","error");
            }
        })
    }
     return (
        <div className="BudgetList-container">
            <br/>
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lista de presupuestos</Card.Title>
                            <a className="btn btn-primary float-end mt-3 mb-3" href="/budget/new">
                                Agregar presupuesto
                            </a>
                            <a className="btn btn-primary float-end mt-3 mb-3 me-2" href="/projectheadinglist">
                                Anexar rubro
                            </a>
                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto</th>
                                        <th>Monto Mensual</th>                                        
                                        <th>Proyeccion</th> 
                                        <th>Total</th>                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        budgets.map((budget:Budget, key:number) => (

                                            <tr key={key}>
                                                <td>{format(new Date(budget.created_at), 'dd/MM/yyyy')}</td>                                                
                                                <td>{budget.concept}</td> 
                                                <td className="text-end">${budget.mount_month}</td>                                               
                                                <td className="text-end">{(budget.mountAssign/budget.mount_month).toFixed(2)}</td>                                               
                                                <td className="text-end">
                                                    ${(budget.mountAssign ? budget.mountAssign : 0 ).toFixed(2)}
                                                </td>                                               
                                                <td>
                                                    <div className="table-options">
                                                        <a href={"/budget/edit/"+budget._id} className="btn btn-secondary ms-2 mt-1">
                                                            Editar
                                                        </a>
                                                        <button onClick={() =>{confirmRemove(budget)}} className="btn btn-danger ms-2 mt-1">
                                                            Eliminar
                                                        </button>
                                                        <a href={"/budget/details/"+budget._id} className="btn btn-secondary ms-2 mt-1">
                                                            Ver detalle
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar presupuesto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el presupuesto {selectedBudget?.concept}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeBudget}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default BudgetList;