import { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Modal, Button } from 'react-bootstrap';
import { Account, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function AccountList(){

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<Partial<Account>>({});
    const [showRemove, setRemove] = useState(false);

    useEffect(()=>{
        getAccounts();
    },[]);

    const getAccounts = () => {
        request('/api/accounts',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setAccounts(result.data);
            }
        })
    }

    const confirmRemove = (account:Account) => {    
        setSelectedAccount(account);
        setRemove(true);
    }

    const removeAccount = () => {
        setRemove(false);
        Swal.fire("Cuenta","Eliminando cuenta...");
        Swal.showLoading();
        request('/api/accounts/'+selectedAccount._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getAccounts();
            }else{
                Swal.fire("Cuenta", result.error.message.error ,"error");
            }
        })
    }
    return (
        <div id="users-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Cuentas
                            </Card.Title>
                            <a className="btn btn-primary float-end mt-3 mb-3" href="/accounts/new">
                                Nueva cuenta
                            </a>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Clabe</th>
                                        <th>Situación Financiera</th>
                                        <th>Saldo</th>
                                        <th>Cuenta</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        accounts.map((account:Account,key:number)=>(                                    
                                            <tr key={key}>
                                                <td>{account.name}</td>
                                                <td>{account.clabe}</td>
                                                <td>{account.financial_situation}</td>
                                                <td>{account.balance}</td>
                                                <td>{account.account}</td>
                                                <td>
                                                    <div className="table-options">
                                                        <a href={"/accounts/edit/"+account._id} className="btn btn-secondary">
                                                            Editar
                                                        </a>
                                                        <button onClick={()=>{confirmRemove(account)}} className="btn btn-danger ms-2">
                                                            Eliminar
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar cuenta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar la cuenta {selectedAccount?.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeAccount}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AccountList;