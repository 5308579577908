import ExpenseList from '../../components/ExpenseList';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card, FloatingLabel, Modal, Collapse} from 'react-bootstrap';
import { ExpenseReport, ResponseResult, RouteParam, Expenses } from '../../types';
import { useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ExpenseReportApproveReject(){    
    const { id } = useParams<RouteParam>();
    const [formData, setFormData] = useState<Partial<ExpenseReport>>({});   
    const [message, setMessage] = useState('');
    const[open, setOpen] = useState(false); 
    const [showApprove, setApprove] = useState(false);
    const [showReject, setReject] = useState(false);

    const getExpenseReport = () => {
        Swal.fire("Informe", "Cargando gastos...");
        Swal.showLoading();
        request('/api/expensereport/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                setFormData(result.data);                
            }else{
                Swal.fire("Informe", "Ocurrió un error al cargar los gastos", "error");
            }
        })       
    }
    useEffect(()=>{
        getExpenseReport()
    },[]);

    const confirmApprove = () => {
        setApprove(true);
    } 

    const ApproveExpenseReport = () => {
        Swal.fire("Informe", "Aprobando el informe...");
        Swal.showLoading();
        request('/api/expensereport/approvereject/approve/'+id,{
            method: 'PUT'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                Swal.fire("Informe", "El informe se aprobó correctamente");
                window.location.href = "/expenseReport";
            }else{
                Swal.fire("Informe", "Ocurrió un error al aprobar el informe", "error");
            }
        })

    }

    const confirmReject = (e:React.SyntheticEvent) => {
        e.preventDefault();
        setReject(true);
    }

    const RejectExpenseReport = (e:React.SyntheticEvent) => {
        e.preventDefault();
        Swal.fire("Informe", "Rechazando el informe...");
        Swal.showLoading();
        request('/api/expensereport/approvereject/reject/'+id,{
            method: 'PUT',
            body: JSON.stringify({
                reject_message: message
            })
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                Swal.fire("Informe", "El informe se rechazó correctamente");
                window.location.href = "/expenseReport";
            }else{
                Swal.fire("Informe", "Ocurrió un error al rechazar el informe", "error");
            }
        })

    }

   const rejectMessageHandle = (e:React.ChangeEvent<HTMLInputElement>) => {
       setMessage(e.target.value);
   }

    return (
        <div id="ExpenseReports-form">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Detalles del informe de gastos</Card.Title>
                            <Form>
                                <Form.Group className="mb-3" controlId="viaticDelivered">
                                    <Form.Label>Viáticos entregados:</Form.Label>
                                    <Form.Control required type="number" name="viatic_delivered" placeholder="" defaultValue={formData.viatic_delivered} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="destiny">
                                    <Form.Label>Destino:</Form.Label>
                                    <Form.Control required type="text" name="destiny" placeholder="" defaultValue={formData.destiny} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodOf">
                                    <Form.Label>Periodo de:</Form.Label>
                                    <Form.Control required type="date" name="period_of" placeholder=""  defaultValue={formData.period_of?.split('T')[0]} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="periodTo">
                                    <Form.Label>Hasta:</Form.Label>
                                    <Form.Control required type="date" name="period_to" placeholder=""  defaultValue={formData.period_to?.split('T')[0]} disabled = {true}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="key">
                                    <Form.Label>Folio:</Form.Label>
                                    <Form.Control required type="text" name="key" placeholder="" defaultValue={formData.key} disabled = {true}/>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>  
                    <div className="mt-5">
                        <ExpenseList idReport={id ? id : '1'}></ExpenseList>
                    </div>  
                    {
                        formData.status == "Revisión" && (
                        <div>
                            <p className = "ms-2 mt-4"> Este informe se envió a revisión</p>
                                <div>
                                    <Button onClick = {confirmApprove} className = "btn-primary ms-2 mt-4 mb-4">Aprobar</Button>
                                    <Button onClick={() => setOpen(!open)} aria-controls="show-reject-message" aria-expanded={open} className="btn-danger ms-2 mt-4 mb-4">Rechazar</Button>  
                                        {
                                            <Collapse in={open}>
                                            <div id="show-reject-message">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Mensaje:</Card.Title>
                                                        <Form onSubmit = {confirmReject}>
                                                            <Form.Group className="mb-3" controlId="reject">
                                                                <FloatingLabel controlId="floatingTextarea" label="Razón del rechazo:" className="mb-3">
                                                                    <Form.Control required  name="rejectMessage" as="textarea" onChange={rejectMessageHandle}/>
                                                                </FloatingLabel>
                                                                <Button type="submit" className="btn-danger mt-2">Confirmar rechazo</Button>
                                                            </Form.Group>
                                                        </Form>
                                                </Card.Body>
                                            </Card> 
                                            </div>
                                            </Collapse>
                                        }
                                    <a href="/expensereport" className="btn btn-secondary ms-2 mt-4 mb-4">Regresar</a>   
                                </div>
                        </div>
                        )
                    }  
                       {
                        formData.status == "Aprobado" && (
                        <div>
                            <p className = "ms-2 mt-4"> Este informe ha sido aprobado</p>
                            <a href="/expensereport" className="btn btn-secondary ms-2 mt-4">Regresar</a>
                        </div>
                        )
                    }  
                       {
                        formData.status == "Rechazado" && (
                        <div>
                            <p className = "ms-2 mt-4"> Este informe ha sido rechazado</p>
                            <a href="/expensereport" className="btn btn-secondary ms-2 mt-4">Regresar</a>
                        </div>
                        )
                    }                                  
                </Col>
            </Row>
            <Modal show={showApprove} onHide={()=>{setApprove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Aprobar Informe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de aprobar el informe?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setApprove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={ApproveExpenseReport}>
                        Sí, estoy seguro
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showReject} onHide={()=>{setReject(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Rechazar Informe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de rechazar el informe? con la razon "{message}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setReject(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={RejectExpenseReport}>
                        Sí, estoy seguro
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ExpenseReportApproveReject;