import React, { useState, useEffect } from 'react';
import {Button, Form, Card, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import { ResponseResult, Budget, RouteParam} from '../../types';
import { request } from '../../services/Request';
import { useParams } from 'react-router-dom';

function BudgetEdit(){

    const { id } = useParams<RouteParam>();
    const [budgetData, setBudgetData] = useState<Partial<Budget>>({});

    const getBudget = () => {
        Swal.fire("Presupuesto", "Cargando presupuesto...");
        Swal.showLoading();
        request('/api/budget/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){                   
                setBudgetData(result.data);                
            }else{
                Swal.fire("Informe", "Ocurrió un error al cargar el informe", "error");
            }
        })
    }
    
    useEffect(()=>{
        getBudget();
        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    }, []);
    
    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        Swal.fire("Presupuestos", "Cambiando registro...");
        Swal.showLoading();
        request("/api/budget/"+id,{
            method: 'PUT',
            body: JSON.stringify(budgetData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.href="/budget";
            }else{
                Swal.fire("Presupuestos", "Ocurrió un error al editar el presupuesto", "error");
            }
        })
    }

    const onChange = (e:any)=>{
        let data = budgetData;
        if(e.target.name == 'mount_month' && e.target.value < 0){
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            data[e.target.name] = 0;
        }else{
            data[e.target.name] = e.target.value;
        }
        setBudgetData(data);
    }

    return (
        <div id="budget-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Editar presupuesto</Card.Title>
                            <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="concept">
                                    <Form.Label>Concepto</Form.Label>
                                    <Form.Control required type="text" placeholder="" defaultValue={budgetData.concept} name="concept" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="mount_month">
                                    <Form.Label>Monto mensual</Form.Label>
                                    <Form.Control required type="number" step="0.01" placeholder="" defaultValue={budgetData.mount_month} name="mount_month" onChange={onChange}/>
                                </Form.Group> 
                                <Row className="mt-5">
                                    <Col xs={12} md={2}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Editar
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a className="ml-3 btn btn-danger w-100" href="/budget">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default BudgetEdit;