import React, { useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ReportHeadingYearly(){

    const [start, setStart] = useState<Date>();
    const [end, setEnd] = useState<Date>();

    const report = () => {
        Swal.fire("Movimientos","Enviando correo...");
        Swal.showLoading();
        request('/api/movement/report/year?start='+(start ? start.toISOString() : '')+'&end='+( end ? new Date(end).toISOString() : ''),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Reporte", "El reporte se ha enviado a su cuenta de correo", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar su reporte", "error");
            }
        })
        .catch((error)=>{

        });
    }

    const onChange = (e:any) => {
        if(e.target.name == 'start'){
            setStart( new Date(e.target.value) );
        }else if(e.target.name == 'end'){
            setEnd( new Date(e.target.value) );
        }
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reporte mensual
                            </Card.Title>
                            <Row className="align-items-end">
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="start">
                                        <Form.Label>Desde</Form.Label>
                                        <Form.Control type="date" placeholder="" name="start" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="end">
                                        <Form.Label>Hasta</Form.Label>
                                        <Form.Control type="date" placeholder="" name="end" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:2}}>
                                    <button className="btn btn-primary w-100" onClick={report}>
                                        Enviar reporte
                                    </button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportHeadingYearly;