import React, {useEffect, useState } from 'react';
import { Table, Modal, Button, Card, Row, Col, Dropdown } from "react-bootstrap";
import { ExpenseReport, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

function ExpenseReportList(){

    const [expenseReports, setExpenseReports] = useState<ExpenseReport[]>([]);    
    const [selectedExpenseReport, setSelectedExpenseReport] = useState<Partial<ExpenseReport>>({});
    const [showRemove, setRemove] = useState(false);    
    const [showRemoveFiles, setRemoveFiles] = useState(false);    


    useEffect( () => {            
        getExpenseReports();
      }, []);            
     
      const getExpenseReports = () => {
        Swal.fire("Informes", "Cargando los informes...");
        Swal.showLoading();
        request('/api/expensereport',{})
        .then((result:ResponseResult)=>{
           if(result.data){            
            setExpenseReports(result.data);
            Swal.close();
           }else{
            Swal.fire("Nuevo informe de gastos", "Ocurrió un error al mostrar el informe de gastos");
           }
        })
      }

      const confirmRemove = (ExpenseReport:ExpenseReport) => {
        if(ExpenseReport.expenses.length > 0){
            setRemoveFiles(true);
            setSelectedExpenseReport(ExpenseReport);
        }else{
            setRemove(true);
            setSelectedExpenseReport(ExpenseReport);
        }
      } 

      const removeExpenseReport = () => {
        setRemove(false);
        setRemoveFiles(false);
        Swal.fire("Informe de gastos","Eliminando informe...");
        Swal.showLoading();
        request('/api/expensereport/'+selectedExpenseReport._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getExpenseReports();
            }else{
                Swal.fire("Informe de gastos","Ocurrió un error al eliminar el informe","error");
            }
        })
      }
     return (
        <div className="ExpenseReportList-container">
            <br/>
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lista de informes</Card.Title>
                            <a className="btn btn-primary float-end mt-3 mb-3" href="/expenseReport/new">
                                Agregar informe
                            </a>
                            <Table striped bordered hover size='sm' className="bg-light text-dark">
                                <thead>
                                    <tr>
                                        <th>Nombre del comisionado</th>
                                        <th>Folio</th>
                                        <th>Fecha</th>                                        
                                        <th>Destino</th> 
                                        <th>Viáticos entregados:</th>   
                                        <th>Estatus:</th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        expenseReports.map((expensereport:ExpenseReport, key:number) => (

                                            <tr key={key}>
                                                <td>{expensereport.user.name + " " +expensereport.user.lastname}</td>                                                
                                                <td>{expensereport.key}</td> 
                                                <td>{format(new Date(expensereport.date_report), 'dd/MM/yyyy')}</td> 
                                                <td>{expensereport.destiny}</td>  
                                                <td className="text-end">${expensereport.viatic_delivered}</td>
                                                <td>{expensereport.status}</td>                                              
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown">
                                                            Opciones
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href={"/expensereport/edit/"+expensereport._id}>Editar</Dropdown.Item>
                                                            <Dropdown.Item href={"/expensereport/expenseapprovereject/"+expensereport._id}>Ver detalle</Dropdown.Item>
                                                            {
                                                                expensereport.status == 'Aprobado' && (
                                                                    <Dropdown.Item href={"/api/expensereport/download/"+expensereport._id} target="_blank">Descargar</Dropdown.Item>
                                                                    )
                                                                }
                                                            <Dropdown.Item onClick={() =>{confirmRemove(expensereport)}} >Eliminar</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showRemove} onHide={()=>{setRemove(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar informe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el informe {selectedExpenseReport?.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemove(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeExpenseReport}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRemoveFiles} onHide={()=>{setRemoveFiles(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar informe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar este informe {selectedExpenseReport?.name}?
                    El informe ya contiene gastos y facturas ingresadas. 
                    Si elimina dicho informe perdera todos los datos PERMANENTEMENTE.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRemoveFiles(false)}}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={removeExpenseReport}>
                        Eliminar de todas formas
                    </Button>
                </Modal.Footer>
            </Modal>

            

        </div>
    )
}
export default ExpenseReportList;