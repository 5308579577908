import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Expenses, ResponseResult } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import {useDropzone} from 'react-dropzone';

function ExpenseEdit({data}:{data:Partial<Expenses>}){


    const [expensesData, setExpensesData] = useState<Partial<Expenses>>({});    
    const [selectedFiles, setFiles]:any = useState([]);

    useEffect(()=>{  
        getExpenses();
        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    },[data]);

    const getExpenses = () => {
        request('/api/expensereport/showexpense/'+data._id,{
            method: 'GET'
        })
        .then((result:ResponseResult)=>{            
            if(result.data){                
                setExpensesData(result.data[0]);
            }else{
                Swal.fire("Gastos", "Ocurrió un error al cargar el gasto", "error");
            }
        })
    }
    
    const onDrop = (acceptedFiles:any) => {
        let files = selectedFiles;
        files = files.concat(acceptedFiles);        
        setFiles(files);
    };

    const removeFile = (index:number) => {
        setFiles( selectedFiles.filter((item:any, key:number) => key !== index) );
    }

    const {getRootProps, getInputProps } = useDropzone({multiple:true, onDrop: onDrop});

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();    
        Swal.fire("Gastos", "Cambiando gasto...");
        Swal.showLoading();
        if(expensesData.type_of_change == 0){
            Swal.fire('Gasto', 'El valor del tipo de cambio no puede ser 0 ni menor a él mismo','error');
            return false;
        }
        if(selectedFiles.length == 0 && expensesData.invoices?.length == 0 ){
            Swal.fire('Gasto', 'El gasto debe tener al menos una factura','error');
            return false;
        }else{           
            const newData = new FormData();
            for(let field in expensesData){
                if(field == 'invoices'){  
                    newData.append('invoices', JSON.stringify(expensesData[field]));
                }else{                    
                    newData.append(field, expensesData[field]); 
                }      
            }
            for(let file of selectedFiles){
                newData.append('invoices', file);
            }
            request("/api/expensereport/editexpense/"+data._id,{
                method: 'PUT',
                file:true,
                body: newData
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    window.location.reload();
                }else if(result.error.status == 420){
                    Swal.fire("Gastos", "El gasto ya existe", "error");
                }else{
                    Swal.fire("Gastos", "Ocurrió un error al guardar el gasto", "error");
                }
            })
        }
    }

    const removeInvoice = (id:any) => {
        Swal.fire("Informe de gastos","Eliminando factura...");
        Swal.showLoading();        
        request('/api/expensereport/rminvoice/'+id,{
            method: 'PUT',
            body: JSON.stringify({expenseid: expensesData._id})
        })        
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getExpenses();
            }else{
                Swal.fire("Informe de gastos","Ocurrió un error al eliminar la factura","error");
            }
        })
    }

    const onChange = (e:any)=>{
        let data = expensesData;
        if(e.target.value == "MXN"){
            data.type_of_change = 1;
        }
        if(e.target.name == 'mount' && e.target.value < 0){
            (document.getElementById(e.target.id) as HTMLInputElement).value = '0';
            data[e.target.name] = 0;
        }else{
            data[e.target.name] = e.target.value;
        }
        setExpensesData({...expensesData,...data});
    }

    return (
        <div id="projects-form">
            <Card>
                <Card.Body>
                    <Card.Title>Editar gasto</Card.Title>
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3" controlId="voucher">
                            <Form.Label>Comprobante</Form.Label>
                            <Form.Select name="voucher" onChange={onChange} required>
                                <option value="Factura" selected={ expensesData['voucher'] === 'Factura' }>Factura</option>
                                <option value="Vale" selected={ expensesData['voucher'] === 'Vale' }>Vale</option>
                                <option value="Vale Azul" selected={ expensesData['voucher'] === 'Vale Azul' }>Vale Azul</option>
                                <option value="Ticket" selected={ expensesData['voucher'] === 'Ticket' }>Ticket</option>                                                    
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="concept">
                            <Form.Label>Concepto:</Form.Label>
                            <Form.Control type="text" name="concept" defaultValue = {expensesData.concept} onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="coin">
                            <Form.Label>Moneda:</Form.Label>
                            <Form.Select name="coin" onChange={onChange} required>
                                <option value="MXN" selected={ expensesData['coin'] === 'MXN' }>Pesos</option>
                                <option value="USD" selected={ expensesData['coin'] === 'USD' }>Dólares</option>                                                                                                       
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="dateExpense">
                            <Form.Label>Fecha del gasto:</Form.Label>
                            <Form.Control type="date" name="dateExpense" defaultValue= {expensesData.dateExpense?.split('T')[0]} onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="mount">
                            <Form.Label>Cantidad:</Form.Label>
                            <Form.Control type="number" step="0.01" name="mount" defaultValue={expensesData.mount} onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                        </Form.Group>
                        {
                                expensesData.coin == "USD" && (
                                    <Form.Group className="mb-3" controlId="type_of_change">
                                        <Form.Label>Tipo de cambio:</Form.Label>
                                        <Form.Control type="number" step="0.01" name="type_of_change" defaultValue={expensesData.type_of_change} placeholder="" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e)} required/>
                                    </Form.Group>
                                )
                            }
                        <hr />
                            <div className='file-drop justify-center mt-10' {...getRootProps()}>
                                <input {...getInputProps({multiple:true})} />
                                <p className="text-center mb-4">Arrastra y suelta tus archivos o presiona aquí para elegirlos.</p> 
                            </div>
                            <p><b>Archivos seleccionados:</b></p>
                            <ul className="mb-5">
                                {
                                    expensesData.invoices?.map((files:any,key:number)=>(
                                        <li key={key}>
                                            <a href={"/api/expenseReport/file?id_invoice="+files._id+'&id_report='+data._id}>{files.name}</a><button type="button" className=" mt-1 mb-1 btn btn-danger" onClick={() => removeInvoice(files._id)}>Eliminar</button>
                                        </li>
                                    ))
                                }
                                {
                                    selectedFiles.map((file:any,key:number)=>(
                                        <li key={key}>
                                            { file.name } <button type="button" className=" mt-1 mb-1 btn btn-danger" onClick={() => removeFile(key)}>Eliminar</button>
                                        </li>
                                    ))
                                }
                            </ul>
                        <Row className="mt-5">
                            <Col xs={12} md={2}>
                                <Button variant="primary" type="submit" className="w-100">
                                    Editar gasto
                                </Button>
                            </Col>
                            <Col xs={12} md={2}>
                                <a className="ml-3 btn btn-danger w-100" href="/myexpenses">
                                    Cancelar
                                </a>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ExpenseEdit
