import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Form } from 'react-bootstrap';
import { ResponseResult, MovementList, Project, ReportHeading, ReportProject } from '../../types';
import { request } from '../../services/Request';
import { addDays, startOfMonth, endOfMonth } from 'date-fns';
import Swal from 'sweetalert2';

function ReportHeadingMonth(){

    const [projects, setProjects] = useState<Project[]>([]);
    const [movements, setMovements] = useState<ReportProject[]>([]);
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [selectedProject, setProject] = useState('');
    const [toggleExchange, setToggle] = useState(false);


    useEffect(()=>{
        let startMonth = new Date( startOfMonth(new Date()) );
        let endMonth = new Date( endOfMonth(new Date()) );
        setStart(startMonth);
        setEnd(endMonth);
        getMovements(startMonth, endMonth);
    }, []);

    const getMovements = (startMonth:Date, endMonth:Date) => {
        Swal.fire("Movimientos","Buscando...");
        Swal.showLoading();
        request('/api/movement/heading/month?start='+(startMonth.toISOString())+'&end='+(  new Date(addDays(endMonth,1)).toISOString()),{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setMovements(result.data);
                setToggle(true);
            }
            Swal.close();
        })
        .catch((error)=>{

        });
    }    

    const report = () => {
        Swal.fire("Movimientos","Enviando correo...");
        Swal.showLoading();
        request('/api/movement/report/month?start='+(start.toISOString())+'&end='+(  new Date(addDays(end,1)).toISOString()),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Reporte", "El reporte se ha enviado a su cuenta de correo", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar su reporte", "error");
            }
        })
        .catch((error)=>{

        });
    }

    const getTotal = (index:number) => {
        let total = 0;
        if(movements[index]){
            for(let j=0; j<movements[index].headings.length; j++){
                total += (movements[index].headings[j].previous_total) - (movements[index].headings[j].amount_mxn)
            }
        }
        return total.toFixed(2);
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reporte de proyectos en mes actual
                            </Card.Title>
                            <Row className="align-items-end">
                                <Col xs={12} md={{span:4}}>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                </Col>
                                <Col xs={12} md={{span:2}}>
                                </Col>
                                {
                                    toggleExchange && (
                                        <Col xs={12} md={{span:2}}>
                                            <button className="btn btn-secondary w-100" onClick={report}>
                                                Enviar al correo
                                            </button>
                                        </Col>
                                    )
                                }
                            </Row>
                            {
                                movements.map((movement:ReportProject, keyMovement:number)=>(
                                    <React.Fragment>
                                        <h6><b>{movement.name}</b></h6>
                                        <Table striped bordered hover className="mt-3" key={keyMovement}>
                                            <thead>
                                                <tr>
                                                    <th>Rubro</th>
                                                    <th>Pesos</th>
                                                    <th>USD gastados</th>
                                                    <th>USD mes pasado</th>
                                                    <th>USD sobrante</th>
                                                    <th>Pesos sobrantes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    movement.headings.map((movement:ReportHeading,key:number)=>(
                                                        <tr key={key}>
                                                            <td>{ movement.heading_name }</td>
                                                            <td className="text-end">$ { (movement.amount_mxn).toFixed(2) }</td>
                                                            <td className="text-end">$ { (movement.amount_mxn / movement.dollar_value).toFixed(2)}</td>
                                                            <td className="text-end">$ { (movement.previous_total / movement.dollar_value).toFixed(2) }</td>
                                                            <td className="text-end">$ { ((movement.previous_total / movement.dollar_value) - (movement.amount_mxn / movement.dollar_value)).toFixed(2)}</td>
                                                            <td className="text-end"> $ { ((movement.previous_total) - (movement.amount_mxn)).toFixed(2) }</td>
                                                        </tr>
                                                    ))
                                                }
                                                        <tr>
                                                            <td colSpan={5}>
                                                                Total
                                                            </td>
                                                            <td className="text-end">
                                                                $ { getTotal(keyMovement) }
                                                            </td>
                                                        </tr>
                                            </tbody>
                                        </Table>
                                    </React.Fragment>
                                ))
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportHeadingMonth;
