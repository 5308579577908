import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Account, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function AccountNew(){

    const [accountData, setAccountData] = useState<Partial<Account>>({});

    useEffect(()=>{
        document.addEventListener("wheel", function(event){
            const element:any = document.activeElement;
            if(element && element.type === "number"){
                element.blur();
            }
        });
    }, []);

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        Swal.fire("Cuenta", "Guardando registro...");
        Swal.showLoading();
        request("/api/accounts",{
            method: 'POST',
            body: JSON.stringify(accountData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Cuenta añadida exitosamente", "", "success");
                window.location.href="/accounts";
            }else{
                Swal.fire("Cuenta", "Ocurrió un error al guardar la cuenta", "error");
            }
        })
    }

    const onChange = (e: any)=>{
        const data = accountData;
        data[e.target.name] = e.target.value;
        setAccountData(data);
    }

    return (
        <div id="users-form">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Nueva cuenta</Card.Title>
                            <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" placeholder="Ingrese el nombre de la cuenta" name="name" onChange={onChange} required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="clabe">
                                    <Form.Label>Clabe</Form.Label>
                                    <Form.Control type="number" placeholder="Ingrese la cuenta CLABE de la cuenta" name="clabe" step="0.01" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="financial_situation">
                                    <Form.Label>Situación Financiera</Form.Label>
                                    <Form.Control type="text" placeholder="Ingrese el banco al cual pertenece la cuenta" name="financial_situation" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="balance">
                                    <Form.Label>Saldo</Form.Label>
                                    <Form.Control type="number" placeholder="Ingrese el saldo de la cuenta" name="balance" step="0.01" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="account">
                                    <Form.Label>Cuenta</Form.Label>
                                    <Form.Control type="number" placeholder="Ingrese el numero de la cuenta" name="account" step="0.01" onChange={onChange}/>
                                </Form.Group>
                                <Row className="mt-5">
                                    <Col xs={12} md={2}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Guardar
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a className="ml-3 btn btn-danger w-100" href="/accounts">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AccountNew;